<template>
	<ion-modal
		canDismiss="false"
		ref="modal"
		:is-open="feedbackAttemptModalOpen"
	>
        <ion-header>
            <div class="feedback-attempt-modal__header">
                <p slot="start"><span>{{skillData.title}} Feedback</span></p>
                <p slot="end">
                    <ion-button
                        @click="$emit('openSkillInfo', skillData)" class="feedback-attempt-modal__info-button"
                    ><ion-icon class="feedback-attempt-modal__icon" :icon="informationCircle"></ion-icon></ion-button></p>
            </div>
        </ion-header>
        <ion-content scroll-y class="feedback-attempt-modal">
            <ion-list lines="full" class="ion-padding-horizontal ion-padding-vertical skill-list" v-if="skillData.items">
                <template v-for="item in skillData.items">
                    <div class="feedback-attempt-modal__item">
                        <ion-checkbox
                            :modelValue="item.selected || false"
                            @update:modelValue="item.selected = $event"
                        />
                        <span class="feedback-attempt-modal__text"><b>{{ item.description }}</b></span>
                    </div>
                </template>
            </ion-list>
        </ion-content>
        <ion-footer>
            <ion-list class="feedback-attempt-modal__bottom">
                <ion-item>
                    <ion-input
                        placeholder="Enter note"
                        type="text"
                        :value="skillData.notes"
                    />
                </ion-item>
                <ion-item>
                    <span class="feedback-attempt-modal__complete"><b>Mark Complete</b></span>
                    <ion-checkbox
                        :modelValue="skillData.completed"
                        @update:modelValue="skillData.completed = $event"
                    />
                </ion-item>
            </ion-list>
            <div class="feedback-attempt-modal__buttons">
                <ion-button
                    @click="closeModal"
                    class="cancel-button"
                >
                    CLOSE
                </ion-button>
                <ion-button color="success" @click="completeFeedbackAttempt">
                    SUBMIT
                </ion-button>
            </div>
        </ion-footer>

    </ion-modal>
</template>

<script>
import { IonModal } from '@ionic/vue';
import { defineComponent, ref } from 'vue';
import { useStore } from "vuex";
import {
	informationCircle
} from 'ionicons/icons';
export default defineComponent({
	components: { IonModal },
	props: {
		selectedSkill: {
			type: Object,
			default: () => {},
		},
		feedbackAttemptModalOpen: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {}
	},
	setup() {
		const store = useStore();

		return {
			store,
            informationCircle
		}
	},
	methods: {
		closeModal() {
			this.$emit('closeFeedbackAttemptModal');
		},
        completeFeedbackAttempt() {
            console.log('TODO: completeFeedbackAttempt')
        },
        closeInfoModal() {
            this.$emil('closeInfoModal');
        }

	},
    computed: {
        skillData() {
			return this.selectedSkill;
		},
    }
});
</script>

<style scoped lang="scss">
.feedback-attempt-modal {
    height: calc(100% - 266px);
    &__header {
        background-color: var(--ion-color-primary);
        color: #ffffff;
        z-index: 10;
        padding: 10px;
        font-weight: 600;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    &__item {
        padding: 10px;
        display: flex;
        justify-content: left;
        align-items: left;
    }

    &__complete {
        padding-right: 10px;
        display: flex;
        justify-content: left;
        align-items: left;
    }

    &__text {
        padding-left: 10px;
    }

    &__icon {
        width: 25px;
        height:25px;
    }

    &__buttons {
        display: flex;
        justify-content: space-between;
        padding: 20px;
    }

    &__bottom {
        padding: 15px;
        ion-input {
            &:first-child {
                padding-bottom: 10px;
                --background: transparent;
                --border-radius: var(--ion-border-radius);
                --border-color: var(--ion-color-grey-darken-1);
                --border-width: 1px;
                --color: var(--ion-color-primary);
            }
		}
    }

    &__info-button::part(native) {
        box-shadow: none;
    }
}
</style>