import gql from 'graphql-tag';
import { apolloClient } from '../apollo/apolloClient';

export const VEHICLE_FRAGMENT = gql`
	fragment vehicleFragment on Vehicle {
		vehicleID
		name
		year
		make
		model
		license
		VIN
		seats
		currentMileage
	}
`;

export const SKILL_FRAGMENT = gql`
	fragment skillFragment on Skill {
		name
		id
		description
		institution
		skillSetIDs
		feedbackItems {
			id
			feedbackMessage
		}
	}
`;

export const SKILL_SET_FRAGMENT = gql`
	fragment skillSetFragment on SkillSet {
		name
		id
		text
		image
		skills {
			...skillFragment
		}
	}
	${SKILL_FRAGMENT}
`;

export const GET_INSTITUTION_SKILL_SETS_QUERY = gql`
	query skillSets($institutionSlug: ID!) {
		skillSets(institutionSlug: $institutionSlug) {
			...skillSetFragment
		}
	}
	${SKILL_SET_FRAGMENT}
`;

export const GET_INSTITUTION_SKILLS_QUERY = gql`
	query skills($institutionSlug: ID!) {
		skills(institutionSlug: $institutionSlug) {
			...skillFragment
		}
	}
	${SKILL_FRAGMENT}
`;

export const SKILL_PROGRESS_FRAGMENT = gql`
	fragment skillProgressFragment on SkillProgress {
		id
		studentCourseID
		score
		userID
		institution
		completedDate
		completedBy {
			userID
			firstName
			lastName
		}
		attempts {
			id
			date
			success
			completedDate
			score
			instructor {
				userID
				firstName
				lastName
			}
			instanceID
			sessionID
			skillSetID
			note
			feedbackItems {
				id
				feedbackMessage
			}
		}
	}
`;


const STUDENT_INSTRUCTOR_APP_DATA_FRAGMENT = gql`
	fragment studentInstructorAppDataFragment on StudentInstructorAppData {
		id
		user {
			userID
			firstName
			lastName
			birthDate
			phone
			smsCompatible
			email
			address
			city
			state
			zip
			correctiveLensesRequired
			notes {
				adminScheduler {
					noteID
					studentCourseID
					userID
					note
					type
				}
			}
		}
		studentCourse {
			course {
				requiredDriveHours
			}
			studentCourseID
			observationHoursCompleted
			studentCourseStatus
			driveHoursCompleted
		}
		studentSkillProgress {
			...skillProgressFragment
		}
		completedDriveSessions {
			id
			sessionDetails {
				sessionID
				sessionStart
				sessionEnd
				customPickupLocation {
					locationID
					name
					address
				}
				note
				drivingNotes {
					internalNote
					externalNote
				}
			}
			note
			instructor {
				userID
				firstName
				lastName
			}
			date: dateTZ
			location {
				locationID
				name
				address
			}
			instanceID
		}
		scheduledSessions {
			id
			sessionDetails {
				sessionID
				sessionStart
				sessionEnd
				customPickupLocation {
					locationID
					name
					address
				}
				note
			}
			note
			instructor {
				userID
				firstName
				lastName
			}
			date: dateTZ
			location {
				locationID
				name
				address
			}
			instanceID
			vehicle {
				...vehicleFragment
			}
		}
		balanceDue
	}
	${SKILL_PROGRESS_FRAGMENT}
	${VEHICLE_FRAGMENT}
`;


export const SESSION_DETAILS_FRAGMENT = gql`
	fragment sessionDetailsFragment on SessionDetails {
		sessionID
		sessionType
		sessionStart
		sessionEnd
		userID
		studentInstructorAppData {
			...studentInstructorAppDataFragment
		}
		studentCourseID
		note
		drivingNotes {
			internalNote
			externalNote
		}
		status
		customPickupLocation {
			locationID
			name
			address
		}
	}
	${STUDENT_INSTRUCTOR_APP_DATA_FRAGMENT}
`;

export const INSTANCE_INSTRUCTOR_APP_FRAGMENT = gql`
	fragment instanceInstructorAppFragment on TimeSlotInstance {
		id
		date: dateTZ
		timeGroupedSessions {
			sessions {
				...sessionDetailsFragment
			}
		}
		sessions {
			...sessionDetailsFragment
		}
		status
	}
	${SESSION_DETAILS_FRAGMENT}
`;

export const TIMESLOT_INSTRUCTOR_APP_FRAGMENT = gql`
	fragment timeSlotInstructorAppFragment on TimeSlot {
		startTime
		endTime
		note
		institution
		isHidden
		label
		roundToTheHour
		location {
			locationID
			name
			address
		}
		instructor {
			firstName
			lastName
			userID
		}
		timeSlotID
		vehicle {
			...vehicleFragment
		}
		sessionDefinitions {
			sessionStartTime
			sessionEndTime
			observe
			drive
			id
		}
		schools {
			locationID
		}
		zones {
			id
		}
		instances {
			...instanceInstructorAppFragment
		}
	}
	${INSTANCE_INSTRUCTOR_APP_FRAGMENT}
	${VEHICLE_FRAGMENT}
`;


export const GET_INSTRUCTOR_TIME_SLOTS_QUERY = gql`
	query timeSlots($institutionSlug: String!, $instructorID: ID, $date: LocalDate) {
		timeSlots(institutionSlug: $institutionSlug, instructorID: $instructorID, date: $date, showHidden: true) {
			...timeSlotInstructorAppFragment
		}
	}
	${TIMESLOT_INSTRUCTOR_APP_FRAGMENT}
`;

export const GET_INSTRUCTOR_TIME_SLOT_QUERY = gql`
	query timeSlotByInstanceID($institutionSlug: String!, $instanceID: ID!) {
		timeSlotByInstanceID(institutionSlug: $institutionSlug, instanceID: $instanceID) {
			...timeSlotInstructorAppFragment
		}
	}
	${TIMESLOT_INSTRUCTOR_APP_FRAGMENT}
`

export const SWITCH_USER_TO_DRIVING_SESSION_MUTATION = gql`
	mutation switchUserToDrivingSession($switchToDriveData: SessionSwapData!, $existingDriveData: SessionSwapData!, $instanceID: ID!, $expectedTimeSlot: ExpectedTimeSlotInput!) {
		switchUserToDrivingSession(switchToDriveData: $switchToDriveData, existingDriveData: $existingDriveData, instanceID: $instanceID, expectedTimeSlot: $expectedTimeSlot) {
			...timeSlotInstructorAppFragment
		}
	}
	${TIMESLOT_INSTRUCTOR_APP_FRAGMENT}
`;

export const ADD_USER_TO_SESSION_MUTATION = gql`
	mutation addUserToSession($addToSessionData: SessionSwapData!, $instanceID: ID!, $expectedTimeSlot: ExpectedTimeSlotInput!) {
		addUserToSession(addToSessionData: $addToSessionData, instanceID: $instanceID, expectedTimeSlot: $expectedTimeSlot) {
			...timeSlotInstructorAppFragment
		}
	}
	${TIMESLOT_INSTRUCTOR_APP_FRAGMENT}
`;

export const UPDATE_APPOINTMENTS_MUTATION = gql`
	mutation updateAppointmentByInstanceID($instanceID: ID!, $institution: String, $sessions: [EditSessionDetails!]!) {
		updateAppointmentByInstanceID(instanceID: $instanceID, institution: $institution, sessions: $sessions) {
			timeSlot {
				...timeSlotInstructorAppFragment
			}
		}
	}
	${TIMESLOT_INSTRUCTOR_APP_FRAGMENT}
`;

export const UPDATE_VEHICLE_HISTORY_MUTATION = gql`
	mutation addUpdateVehicleHistory($vehicleHistory: EditVehicleHistory!) {
		addUpdateVehicleHistory(vehicleHistory: $vehicleHistory) {
			id
			institution
			sessionID
			instanceID
			vehicleID
			startingMileage
			endingMileage
			date
			notes
		}
	}
`;

export const USER_FRAGMENT = gql`
	fragment userFragment on SchedulerUser {
		userID
		firstName
		lastName
		email
		phone
		smsCompatible
		address
		city
		state
		zip
		birthDate
		driveHoursCompleted
		observationHoursCompleted
		school {
			locationID
			name
		}
		studentCourseID
		studentCourseStatus
		notes {
			noteID
			note
			type
		},
		correctiveLensesRequired
	}
`;

export const USERS_FOR_SCHEDULER_QUERY = gql `
	query usersForScheduler($institution: ID, $studentCourseStatus: [StudentCourseStatus]){
		usersForScheduler(institution: $institution, studentCourseStatus: $studentCourseStatus)  {
			...userFragment
		}
	}
	${USER_FRAGMENT}
`;

export function readFragment(cacheFragmentItem: string) {
	const fragmentTypeName = cacheFragmentItem.split(':')[0];
	const fragmentQueryDef = () => {
		switch (fragmentTypeName) {
			case 'TimeSlot':
				return {
					fragmentName: 'timeSlotInstructorAppFragment',
					fragment: TIMESLOT_INSTRUCTOR_APP_FRAGMENT
				};
			case 'TimeSlotInstance':
				return {
					fragmentName: 'instanceInstructorAppFragment',
					fragment: INSTANCE_INSTRUCTOR_APP_FRAGMENT
				};
			case 'StudentInstructorAppData':
				return {
					fragmentName: 'studentInstructorAppDataFragment',
					fragment: STUDENT_INSTRUCTOR_APP_DATA_FRAGMENT
				};
			case 'Skill':
				return {
					fragmentName: 'skillFragment',
					fragment: SKILL_FRAGMENT
				};
			case 'SkillProgress':
				return {
					fragmentName: 'skillProgressFragment',
					fragment: SKILL_PROGRESS_FRAGMENT
				};
			case 'SessionDetails':
				return {
					fragmentName: 'sessionDetailsFragment',
					fragment: SESSION_DETAILS_FRAGMENT
				};
			case 'SchedulerUser': {
				return {
					fragmentName: 'userFragment',
					fragment: USER_FRAGMENT
				};
			}
			default:
				return null;
		}
	}
	if (!fragmentQueryDef) {
		throw new Error(`No fragment query definition found for ${fragmentTypeName}`);
	}

	const response = apolloClient.cache.readFragment({
		id: cacheFragmentItem,
		fragment: fragmentQueryDef().fragment,
		fragmentName: fragmentQueryDef().fragmentName
	});

	return response;
}

export function writeFragment(cacheFragmentItem: string, data: any) {
	const fragmentTypeName = cacheFragmentItem.split(':')[0];
	const fragmentQueryDef = () => {
		switch (fragmentTypeName) {
			case 'TimeSlot':
				return {
					fragmentName: 'timeSlotInstructorAppFragment',
					fragment: TIMESLOT_INSTRUCTOR_APP_FRAGMENT
				};
			case 'TimeSlotInstance':
				return {
					fragmentName: 'instanceInstructorAppFragment',
					fragment: INSTANCE_INSTRUCTOR_APP_FRAGMENT
				};
			case 'SessionDetails':
				return {
					fragmentName: 'sessionDetailsFragment',
					fragment: SESSION_DETAILS_FRAGMENT
				};
			case 'StudentInstructorAppData':
				return {
					fragmentName: 'studentInstructorAppDataFragment',
					fragment: STUDENT_INSTRUCTOR_APP_DATA_FRAGMENT
				};
			case 'SkillProgress':
				return {
					fragmentName: 'skillProgressFragment',
					fragment: SKILL_PROGRESS_FRAGMENT
				};
			default:
				return null;
		}
	}
	if (!fragmentQueryDef) {
		throw new Error(`No fragment query definition found for ${fragmentTypeName}`);
	}

	const response = apolloClient.cache.writeFragment({
		id: cacheFragmentItem,
		fragment: fragmentQueryDef().fragment,
		fragmentName: fragmentQueryDef().fragmentName,
		data
	});

	return response;
}

export function updateFragment(cacheFragmentItem: string, dataToUpdate: any,) {
	const fragmentTypeName = cacheFragmentItem.split(':')[0];
	const fragmentQueryDef = () => {
		switch (fragmentTypeName) {
			case 'TimeSlot':
				return {
					fragmentName: 'timeSlotInstructorAppFragment',
					fragment: TIMESLOT_INSTRUCTOR_APP_FRAGMENT
				};
			case 'TimeSlotInstance':
				return {
					fragmentName: 'instanceInstructorAppFragment',
					fragment: INSTANCE_INSTRUCTOR_APP_FRAGMENT
				};
			case 'SessionDetails':
				return {
					fragmentName: 'sessionDetailsFragment',
					fragment: SESSION_DETAILS_FRAGMENT
				};
			case 'StudentInstructorAppData':
				return {
					fragmentName: 'studentInstructorAppDataFragment',
					fragment: STUDENT_INSTRUCTOR_APP_DATA_FRAGMENT
				};
			case 'SkillProgress':
				return {
					fragmentName: 'skillProgressFragment',
					fragment: SKILL_PROGRESS_FRAGMENT
				};
			case 'Vehicle':
				return {
					fragmentName: 'vehicleFragment',
					fragment: VEHICLE_FRAGMENT
				}
			default:
				return null;
		}
	}
	if (!fragmentQueryDef) {
		throw new Error(`No fragment query definition found for ${fragmentTypeName}`);
	}

	const response = apolloClient.cache.updateFragment({
		id: cacheFragmentItem,
		fragment: fragmentQueryDef().fragment,
		fragmentName: fragmentQueryDef().fragmentName,
	}, (data: any) => {
		return {
			...data,
			...dataToUpdate
		}
	});

	return response;
}


//addSkillSetProgressMutation
export const UPDATE_PROGRESS_MUTATION = gql`
	mutation updateSkillProgress($skillProgress: EditSkillProgress!) {
		updateSkillProgress(skillProgress: $skillProgress) {
			...skillProgressFragment
		}
	}
	${SKILL_PROGRESS_FRAGMENT}
`;

export const GET_STUDENTS_SKILL_PROGRESS = gql`
	query multiStudentSkillProgress($studentCourseIDs: [ID!]!) {
		multiStudentSkillProgress(studentCourseIDs: $studentCourseIDs) {
			id
			studentCourseID
			score
			userID
			institution
			completedDate
			completedBy {
				userID
				firstName
				lastName
			}
			attempts {
				id
				date
				# success
				# completedDate
				# score
				# instructor {
				# 	userID
				# 	firstName
				# 	lastName
				# }
				instanceID
				sessionID
				skillSetID
				note
				feedbackItems {
					id
					feedbackMessage
				}
			}
		}
	}
`;