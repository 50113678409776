<template>
	<div :class="['skill-card', (expanded) ? 'skill-card--expanded': '']">
		<div class="skill-card__bar">

			<div class="skill-card__area" v-if="skillSettings.allowMultipleAttempts">
				<MaterialDesignIcon
					color="green"
					icon="checkCircleOutline"
					v-if="skillItem.completedDate && skillSettings.allowSkillCompletion"
				/>
				<div class="skill-card__info">
					<span>{{ skillItem.name }}</span>
					<span
						class="skill-card__complete"
						v-if="skillItem.completedDate && skillSettings.allowSkillCompletion"
					>{{format(skillItem.completedDate, 'MM/dd/yyyy')}} - {{ skillItem.completedBy.firstName }} {{ skillItem.completedBy.lastName }}</span>
				</div>
			</div>

			<IonItem
				button
				class="skill-card__check-button"
				@click="toggleAttempt()"
				lines="none"
				v-if="!skillSettings.allowMultipleAttempts"
			>
				<div
					:class="[
						'skill-card__checkbox',
						(hasCurrentSkillSetAttempt) ? 'skill-card__checkbox--checked' : ''
					]"
					slot="start"
				>
					<MaterialDesignIcon color="light" icon="checkBold" v-if="hasCurrentSkillSetAttempt" />
				</div>
				<IonLabel>{{ skillItem.name }}</IonLabel>
			</IonItem>

			<div class="skill-card__basic-count" v-if="!skillSettings.allowMultipleAttempts && attemptCount > 0">
				<span>{{ attemptCount }}</span>
			</div>

			<div
				class="skill-card__results"
				v-if="skillSettings.allowMultipleAttempts"
			>
				<ProgressItem
					:count="skillItem.successes"
					type="success"
				/>
				<ProgressItem
					:count="skillItem.failures"
					type="failure"
					v-if="skillSettings.allowPositiveNegativeAttempts"
				/>
			</div>

			<IonButton class="skill-card__expansion-button" @click="showSkillDetails = !showSkillDetails">
				<div
					:class="[
						(skillItem.completedDate && skillSettings.allowSkillCompletion) ? 'skill-card__expansion-button--complete' : ''
					]"
				>
					<MaterialDesignIcon :icon="menuIcon" color="white" />
				</div>
			</IonButton>
		</div>

		<div
			class="skill-card__details"
			v-if="showSkillDetails"
		>
			<IonList class="skill-card__feedback-list" v-if="skillItem && skillItem.feedbackItems && modifiedSkillAttemptCheckboxToggle">
				<IonItem lines="full" v-for="feedbackItem in skillItem.feedbackItems" :key="feedbackItem.id">
					<IonCheckbox
						@ionChange="toggleCheckedFeedbackItem($event, feedbackItem)"
						:modelValue="checkedFeedbackItems[feedbackItem.id]"
						slot="start"
					/>
					<IonLabel class="ion-text-wrap">{{ feedbackItem.feedbackMessage }}</IonLabel>
				</IonItem>
			</IonList>

			<IonList class="skill-card__custom-feedback" v-if="hasCurrentSkillSetAttempt && modifiedSkillAttemptCheckboxToggle">
				<IonItem lines="none">
					<IonLabel position="stacked">Custom Skill Feedback</IonLabel>
					<IonTextarea
						@ionChange="updateAttempt()"
						placeholder="Enter A Custom Note"
						v-model="modifiedSkillAttempt.note"
					></IonTextarea>
				</IonItem>
			</IonList>

			<AttemptHistoryItem
				:attempt="attempt"
				:count="(skillAttempts.length - index)"
				v-for="(attempt, index) in skillAttempts"
				v-if="skillAttempts && skillAttempts.length > 0"
			/>
			<div class="skill-card__no-history-attempts" v-else>
				No attempts for skill
			</div>
		</div>

		<ion-modal
			class="confirm-uncheck"
			id="confirm-uncheck"
			:is-open="showConfirmUncheckModal"
			backdrop-dismiss="false"
		>
			<ion-content scroll-y="false" class="confirm-uncheck__container">
				<div class="confirm-uncheck__content">
					<ion-text color="light">
						<p>Unchecking this skill will also remove the current feedback.</p>
						<p>Are you sure you want to remove the check on this skill?</p>
					</ion-text>
					<div class="confirm-uncheck__buttons">
						<ion-button
							class="ion-margin-vertical modal-button cancel-button"
							expand="block"
							part="native"
							@click="showConfirmUncheckModal = false;"
						>
							CANCEL
						</ion-button>
						<ion-button
							class="ion-margin-vertical modal-button remove-button"
							expand="block"
							part="native"
							@click="removeAttempt()"
						>
							REMOVE
						</ion-button>
					</div>
				</div>
			</ion-content>
		</ion-modal>
	</div>
</template>

<script>
import { v4 as uuidv4 } from 'uuid';
import { format } from 'date-fns';
import { IonButton, IonCheckbox, IonLabel, IonInput, IonItem, IonList, IonTextarea } from '@ionic/vue';
import AttemptHistoryItem from '@/view/components/AttemptHistoryItem.vue';
import MaterialDesignIcon from '@/view/components/MaterialDesignIcon.vue';
import ProgressItem from '@/view/components/ProgressItem.vue';
import SkillActions from '@/view/components/SkillActions.vue';
import { deepCopy } from '@/core/util/helpers';
import { useStore } from 'vuex';

export default {
	components: {
		AttemptHistoryItem,
		IonButton,
		IonCheckbox,
		IonInput,
		IonItem,
		IonLabel,
		IonList,
		IonTextarea,
		MaterialDesignIcon,
		ProgressItem,
		SkillActions
	},
	computed: {
		attemptCount() {
			if (!this.skillItem || !this.skillItem.attempts) { return 0; }
			return this.skillItem.attempts.length;
		},
		currentSkillSetAttempt() {
			if (!this.skillItem || !this.skillItem.attempts) { return null; }

			// Check if the attempts array has an item for this skill
			return this.skillItem.attempts.find(si => {
				if (this.skillSettings.trackAttemptedBySkillSet) {
					return si.skillSetID === this.skillSetID;
				} else {
					return si.sessionID === this.store.getters.activeSession.sessionID;
				}
			});
		},
		hasCurrentSkillSetAttempt() {
			if (!this.skillItem || !this.skillItem.attempts) { return false; }

				// Check if the attempts array has an attempt for this skill
				return this.skillItem.attempts.some(si => {
					if (this.skillSettings.trackAttemptedBySkillSet) {
						return si.skillSetID === this.skillSetID;
					} else {
						return si.sessionID === this.store.getters.activeSession.sessionID;
					}
				});
		},
		menuIcon() {
			return this.showSkillDetails ? 'menuUp': 'menuDown';
		},
		skillAttempts() {
			if (!this.skillItem || !this.skillItem.attempts) { return []; }

			let attempts = this.deepCopy(this.skillItem.attempts);

			attempts.sort((a, b) => {
				return (a.date - b.date) * -1;
			});

			return attempts;

		},
		skillSettings() {
			return this.store.getters.skillSettings;
		}
	},
	data() {
		return {
			checkedFeedbackItems: [],
			modifiedSkillAttempt: {
				completedDate: null,
				completedBy: null,
				date: Date.now(),
				delete: false,
				feedbackItems: [],
				id: uuidv4(),
				instructor: this.store.getters.activeInstructor,
				instanceID: this.store.getters.activeSession.instanceID,
				note: '',
				score: 0,
				sessionID: this.store.getters.activeSession.sessionID,
				skillSetID: this.skillSetID,
				success: null
			},
			modifiedSkillAttemptCheckboxToggle: false,
			showConfirmUncheckModal: false,
			showSkillDetails: false
		}
	},
	methods: {
		deepCopy,
		format,
		getCheckedFeedbackItems(selectedAttemptID) {
			const selectedAttempt = (selectedAttemptID) ? this.skillItem.attempts.find((attempt) => attempt.id === selectedAttemptID) : '';

			let feedbackItems = this.skillItem?.feedbackItems?.reduce((accumulator, currentValue) => {
				if (!accumulator[currentValue.id]) {
					accumulator[currentValue.id] = (selectedAttempt) ? selectedAttempt.feedbackItems.some(fi => fi.id === currentValue.id) : false;
				}
				return accumulator;
			}, {});

			return feedbackItems;
		},
		getCurrentSkillSetAttempt() {
			if (!this.skillItem || !this.skillItem.attempts) { return null; }

			// Check if the attempts array has an item for this skill
			return this.skillItem.attempts.find(si => {
				return si.skillSetID === this.skillSetID;
			});
		},
		removeAttempt() {
			this.showConfirmUncheckModal = false;
			this.modifiedSkillAttempt.delete = true;
			this.modifiedSkillAttemptCheckboxToggle = false;
			this.showSkillDetails = false;
			this.updateAttempt();
		},
		toggleAttempt() {
			if (this.hasCurrentSkillSetAttempt) {
				this.showConfirmUncheckModal = true;
			} else {
				this.modifiedSkillAttempt.delete = false;
				this.modifiedSkillAttemptCheckboxToggle = true;
				this.showSkillDetails = true;
				this.updateAttempt();
			}
		},
		toggleCheckedFeedbackItem(event, feedbackItem) {
			if (event.target.checked && !this.modifiedSkillAttempt.feedbackItems?.find(item => item.id === feedbackItem.id)) {
				if (!this.modifiedSkillAttempt.feedbackItems) {
					this.modifiedSkillAttempt.feedbackItems = [];
				}
				this.modifiedSkillAttempt.feedbackItems.push(feedbackItem);
				this.checkedFeedbackItems[feedbackItem.id] = true;
			} else {
				this.modifiedSkillAttempt.feedbackItems = this.modifiedSkillAttempt.feedbackItems.filter(item => item.id !== feedbackItem.id);
				this.checkedFeedbackItems[feedbackItem.id] = false;
			}
			this.updateAttempt();
		},
		updateAttempt() {

			const studentCourseID = this.store.getters.activeSession.driverStudentCourseID;
			const userID = this.store.getters.activeSession.driverUserID;
			const skillID = this.skillItem.id;

			// TODO: Review issue where ID was not being updated with watcher when switching skill sets.
			// Extra check is a current work around.
			if (this.currentSkillSetAttempt) {
				this.modifiedSkillAttempt.id = this.currentSkillSetAttempt.id;
			}

			this.store.dispatch(
				'addSkillAttemptToLocalProgress',
				{
					studentCourseID,
					userID,
					skillID,
					skillAttempt: this.modifiedSkillAttempt
				}
			);

			this.count
		}
	},
	props: {
		skillItem: {
			default: {},
			required: true,
			type: Object
		},
		skillSetID: {
			default: null,
			type: String
		},
		expanded: {
			type: Boolean,
			default: false
		}
	},
	setup() {
		const store = useStore();

		return {
			store
		}
	},
	watch: {
		currentSkillSetAttempt(attempt) {
			if (attempt) {
				this.modifiedSkillAttempt = this.deepCopy(attempt);
				this.checkedFeedbackItems = this.getCheckedFeedbackItems(this.modifiedSkillAttempt.id);
			}
		}
	}
};
</script>

<style lang="scss" scoped>
	.skill-card {
		border-radius: 6px;
		box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.25);
		display: flex;
		flex-direction: column;
		overflow: hidden;

		&__bar {
			align-items: stretch;
			background-color: var(--ion-color-light);
			display: flex;
			font-weight: 700;
			width: 100%;

			ion-item {
				--background: var(--ion-color-light);
				--border-style: none;
				flex-grow: 1;
				height: 100%;
				--padding-start: 10px;

				.skill-card__checkbox {
					align-items: center;
					border: 2px solid var(--ion-color-grey-darken-2);
					border-radius: 150px;
					display: flex;
					height: 30px;
					justify-content: center;
					margin-right: 15px;
					width: 30px;

					&--checked {
						background-color: var(--ion-color-success);
						border-color: var(--ion-color-success);
					}
				}

				ion-label {
					margin: 0;
					text-overflow: inherit;
					white-space: normal;
				}
			}
		}

			&__basic-count {
				align-items: center;
				background: var(--ion-color-light);
				display: flex;
				flex-shrink: 0;
				height: 50px;
				justify-content: center;
				width: 50px;

				span {
					align-items: center;
					background-color: var(--ion-color-grey-darken-1);
					border-radius: 150px;
					color: var(--ion-color-grey-darken-7);
					height: 35px;
					display: flex;
					justify-content: center;
					width: 35px;
				}
			}

			&__expansion-button {
				align-items: center;
				background-color: var(--ion-color-primary);
				display: flex;
				flex-direction: column;
				height: auto;
				justify-content: center;
				margin: 0;
				width: 50px;
			}

		&__details {
			--background: var(--ion-color-primary-lighten-8);
			border-top: 1px solid var(--ion-color-primary);
		}

			&__feedback-list {
				max-height: 269px;
				overflow-y: scroll;
				padding: 0;

				ion-item {
					--background: var(--ion-color-light);
				}
			}

			&__custom-feedback {
				padding: 0;

				ion-label {
					color: var(--ion-color-dark);
					font-weight: 700;
					margin-bottom: 5px;
				}

				ion-textarea {
					background-color: var(--ion-color-light);
					border: 1px solid var(--ion-color-grey-darken-2);
					border-radius: 6px;
					margin-bottom: 15px;
					overflow: hidden;
					padding: 10px;
				}
			}

			&__no-history-attempts {
				background-color: var(--ion-color-grey-darken-1);
				color: var(--ion-color-grey-primary);
				font-weight: 700;
				padding: 5px 10px;
			}
	}

	.skill-card--expanded {
		border-radius: 0;
	}

	.skill-card:last-child {
		border-bottom: none;
	}

	.confirm-uncheck {
		--background: none;
		--border-radius: 6px;
		--box-shadow: none;
		--max-width: 80%;

		&__container {
			--background: none;
		}

		ion-content::part(scroll) {
			--background: none;
			display: flex;
			flex-direction: column;
			justify-content: center;
			padding: 15px;
		}

		&__header {
			color: var(--ion-color-light, #FFF);
			text-align: center;
			font-size: 13px;
			font-style: normal;
			font-weight: 700;
			line-height: 147%;
			letter-spacing: 0.78px;
		}

		&__content {
			background: var(--ion-color-primary-tint);
			border-radius: 6px;
			box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
			padding: 15px 15px 0 15px;
			text-align: center;

			p {
				font-weight: 700;
				margin-bottom: 15px;
			}
		}

		&__buttons {
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			align-items: center;
			gap: 10px;
			width: 100%;


			ion-button {
				width: 100%;
				font-size: 13px;
				font-weight: 700;
				line-height: 147%;
				letter-spacing: 0.78px;
				--border-radius: 6px;
			}

			.cancel-button {
				--background: var(--ion-color-primary-lighten-3, #6B7985);
			}

			.remove-button {
				--background: var(--ion-color-danger);
			}
		}
	}
</style>