import App from './App.vue'
import { createApp, watch } from 'vue'
import router from './core/router';
import { store } from './core/store/store';
import { DefaultApolloClient } from '@vue/apollo-composable'
import { SplashScreen } from '@capacitor/splash-screen';
import Maska from 'maska';

/* V-calendar Styles */
import 'v-calendar/dist/style.css';

/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css';
import '@ionic/vue/css/float-elements.css';
import '@ionic/vue/css/text-alignment.css';
import '@ionic/vue/css/text-transformation.css';
import '@ionic/vue/css/flex-utils.css';
import '@ionic/vue/css/display.css';

/* Theme variables */
import './static/style/variables.scss';
import './static/style/variables-ms-driving-instruction.scss';
import './static/style/variables-ez-street-driving-school.scss';
import './static/style/variables-crash-course.scss';
import './static/style/ionic-adjustments.scss';

/* Ionic Components*/
import {
	IonBadge,
	IonButtons,
	IonCheckbox,
	IonMenuButton,
	IonTitle,
	IonButton,
	IonToolbar,
	IonHeader,
	IonPage,
	IonApp,
	IonContent,
	IonIcon,
	IonItem,
	IonLabel,
	IonList,
	IonListHeader,
	IonMenu,
	IonMenuToggle,
	IonNav,
	IonImg,
	IonNote,
	IonRouterOutlet,
	IonSplitPane,
	IonSelect,
	IonSelectOption,
	IonItemGroup,
	IonCardContent,
	IonCardTitle,
	IonCardHeader,
	IonCardSubtitle,
	IonCol,
	IonGrid,
	IonRadio,
	IonRadioGroup,
	IonRow,
	IonItemDivider,
	IonDatetime,
	IonModal,
	IonBackdrop,
	IonToggle,
	IonTextarea,
	IonCard,
	IonicVue,
	IonInput,
	IonSpinner,
	IonText,
	IonProgressBar,
	IonThumbnail
} from '@ionic/vue';

import { RouterView } from 'vue-router';
import { apolloClient } from './core/apollo/apolloClient';

import Equal from 'equal-vue'
import 'equal-vue/dist/style.css'

import VCalendar from 'v-calendar';


const start = () => {
	if (!store.getters.authInitialized) return;
	if (!store.getters.themeInitialized) return;

	const app = createApp(App)
		.provide(DefaultApolloClient, apolloClient)
		.use(store)
		.use(router)
		.use(IonicVue, {
			mode: 'md',
			scrollAssist: false
		})
		.use(VCalendar, {})
		.use(Maska)
		.use(Equal);
	app.component('ion-badge', IonBadge);
	app.component('ion-buttons', IonButtons);
	app.component('ion-card', IonCard);
	app.component('ion-checkbox', IonCheckbox);
	app.component('ion-menu-button', IonMenuButton);
	app.component('ion-title', IonTitle);
	app.component('ion-card-content', IonCardContent);
	app.component('ion-card-title', IonCardTitle);
	app.component('ion-card-header', IonCardHeader);
	app.component('ion-card-subtitle', IonCardSubtitle);
	app.component('ion-col', IonCol);
	app.component('ion-grid', IonGrid);
	app.component('ion-button', IonButton);
	app.component('ion-toolbar', IonToolbar);
	app.component('ion-header', IonHeader);
	app.component('ion-page', IonPage);
	app.component('ion-app', IonApp);
	app.component('ion-content', IonContent);
	app.component('ion-icon', IonIcon);
	app.component('ion-input', IonInput);
	app.component('ion-item', IonItem);
	app.component('ion-label', IonLabel);
	app.component('ion-list', IonList);
	app.component('ion-list-header', IonListHeader);
	app.component('ion-menu', IonMenu);
	app.component('ion-menu-toggle', IonMenuToggle);
	app.component('ion-nav', IonNav);
	app.component('ion-img', IonImg);
	app.component('ion-note', IonNote);
	app.component('ion-router-outlet', IonRouterOutlet);
	app.component('ion-row', IonRow);
	app.component('ion-split-pane', IonSplitPane);
	app.component('ion-spinner', IonSpinner);
	app.component('ion-select', IonSelect);
	app.component('ion-select-option', IonSelectOption);
	app.component('ion-item-group', IonItemGroup);
	app.component('ion-radio', IonRadio);
	app.component('ion-radio-group', IonRadioGroup);
	app.component('ion-item-divider', IonItemDivider);
	app.component('ion-datetime', IonDatetime);
	app.component('ion-modal', IonModal);
	app.component('ion-backdrop', IonBackdrop);
	app.component('ion-toggle', IonToggle);
	app.component('ion-textarea', IonTextarea);
	app.component('ion-text', IonText);
	app.component('ion-progress-bar', IonProgressBar);
	app.component('ion-thumbnail', IonThumbnail);
	app.component('router-view', RouterView);

	router.isReady().then(() => {
		app.mount('#app');

		SplashScreen.hide();
	});
}

Promise.all([
	store.dispatch('initializeAuth'),
	store.dispatch('initializeTheme')
])
.then(async () => {
	if (store.getters.isAuthenticated) {
		await store.dispatch('initialize');
	}
})
.then(() => start());