<template>
	<ion-page>
		<app-header />
		<progress-message
			:icon="alarm"
			color="warning"
			:message="`You have ${eLearningDaysFromLockDate} day${eLearningDaysFromLockDate === 1? '' : 's'} to complete your course before it locks on ${format(classroomUserProgress.lockDate, 'MMM do, yyyy')}`"
			:isButton="true"
			type="eLearningCountdownWarning"
			v-if="showeLearningExtensionWarning"
		></progress-message>
		<NotificationCard
			:notification="notification"
			v-for="(notification, index) in notificationsForScheduler"
			:key="index"
		/>
		<ion-content fullscreen scrollY="true">
			<calendar
				ref="calendar"
				class="calendar"
				dayContentDisabled
				:is-dark="darkMode === 'dark'"
				is-expanded
				mode="dateTime"
				:attributes="calendarAttributes"
				:from-page="{month: startOfViewedMonth.getMonth()+1, year: startOfViewedMonth.getFullYear()}"
				@dayclick="selectDay"
				@update:to-page="toNewPage"
				v-if="!loading && store.getters.selectedUser || !isParent"
			>
				<template v-slot:day-content v-if="loading">
					<ion-skeleton-text animated style="border-radius: 150px; height: 28px; margin: 0 auto;  width: 28px;" />
				</template>
			</calendar>
			<ion-toolbar color="light">
				<ion-title size="medium">{{ selectedDate ? format(parseISO(selectedDate), 'EEEE, MMMM d') : '' }}</ion-title>
				<ion-button
					slot="end"
					@click="viewingFilters = true"
					class="filter-button"
					shape="round"
				>
					<span slot="start" :class="['filter-button__icon', filterCount && 'filter-button--active']">
						<ion-icon :icon="filter" size="small"></ion-icon>
					</span>
					<span slot="end" class="filter-button__text" v-if="filterCount" color="primary">{{ filterCount }}</span>
				</ion-button>
				<ion-progress-bar type="indeterminate" v-if="loading"></ion-progress-bar>
			</ion-toolbar>
			<alert-bar
				v-for="note in currentUser?.notes?.userApp || []"
				:key="note.noteID"
				:htmlText="note.note"
				:type="note.type"
			/>

			<Filters
				:instructors="instructors"
				:locations="locations"
				:schools = "schools"
				:zones = "zones"
				:filters = "filters"
				:filterRestrictions="filterRestrictions"
				@apply-filters="applyFilters"
				@reset-filters="resetFilters"
				:is-open="viewingFilters"
				@didDismiss="viewingFilters = false"
			/>

			<div class="scrollable-content">
				<SchedulerLegend :showSessionType="!observeDisabled" v-if="!selectedDate" />
				<ion-list class="ion-padding timeslot-list" lines="full" v-else>
					<ion-item class="ion-margin-bottom" color="danger" v-if="futureAppointmentsLocked?.balance">
						<m-d-i-alert-octagon class="mdi-icon"></m-d-i-alert-octagon>
						<ion-label class="ion-text-wrap">
							Your account has an outstanding balance.  This must be paid in full before scheduling your next appointment.
						</ion-label>
					</ion-item>
					<ion-item class="ion-margin-bottom" color="danger" v-if="futureAppointmentsLocked?.learning">
						<m-d-i-alert-octagon class="mdi-icon"></m-d-i-alert-octagon>
						<ion-label class="ion-text-wrap">
							Sorry, you must complete your learning before you can schedule additional drives.
						</ion-label>
					</ion-item>
					<ion-item class="ion-margin-bottom" color="danger" v-if="futureAppointmentsLocked?.roadTest">
						<m-d-i-alert-octagon class="mdi-icon"></m-d-i-alert-octagon>
						<ion-label class="ion-text-wrap">
							Sorry, you must schedule your road test before you can schedule additional drives.
						</ion-label>
					</ion-item>
					<ion-item class="ion-margin-bottom" color="danger" v-if="futureAppointmentsLocked?.maxDrives">
						<m-d-i-alert-octagon class="mdi-icon"></m-d-i-alert-octagon>
						<ion-label class="ion-text-wrap">
							You have exceeded your maximum number of scheduled driving appointments.
						</ion-label>
					</ion-item>
					<ion-item class="ion-margin-bottom" color="danger" v-if="futureAppointmentsLocked?.maxObserves">
						<m-d-i-alert-octagon class="mdi-icon"></m-d-i-alert-octagon>
						<ion-label class="ion-text-wrap">
							You have exceeded your maximum number of scheduled observation appointments.
						</ion-label>
					</ion-item>
					<ion-item class="ion-margin-bottom" v-if="isInWaitingPeriod">
						<ion-icon color="danger" :ios="alertCircle" :md="alertCircle" slot="start"></ion-icon>
						<ion-label class="ion-text-wrap">
							You are not eligible to schedule your next appointment until {{ gapFromMostFutureScheduledAppointment }} or after.
						</ion-label>
					</ion-item>
					<ion-item class="ion-margin-bottom" v-if="noAvailableAppointments">
						<ion-icon color="danger" :ios="alertCircle" :md="alertCircle" slot="start"></ion-icon>
						<ion-label class="ion-text-wrap">
							There are currently no appointments available for scheduling on this day, please check again later.
						</ion-label>
					</ion-item>
					<div v-if="!store.getters.institutionSettings?.schedulerSettings?.sessionSelection?.granularSessionSelection">
						<time-slot-adjustment-item
							:disableButton="disableTimeSlotButton(timeSlot)"
							:hideTimeSlot="hideTimeSlot(timeSlot)"
							:day="selectedDate"
							:key="timeSlot.timeSlotID + `_${index}`"
							:timeSlot="timeSlot"
							:sessionGroup="sessionGroup"
							@refetchTimeSlots="refetchTimeSlots"
							v-for="({timeSlot, sessionGroup}, index) in groupedSessions"
						/>
					</div>
					<div v-else>
						<time-slot-granular-adjustment-item
							:timeSlot="timeSlot"
							:key="timeSlot.timeSlotID"
							v-for="(timeSlot) in timeSlotsOnSelectedDate"
							@updateReservationState="updateReservationState"
							@cancelReservation="openCancelModal"
							:timeSlotIDOfSelectedReservations="timeSlotIDOfSelectedReservations"
							:inWaitingPeriod="isInWaitingPeriod"
							:futureAppointmentsLocked="futureAppointmentsLocked"
							:timeSlotsOnSelectedDate="timeSlotsOnSelectedDate"
						/>
					</div>
				</ion-list>
			</div>
			<reservation-confirm-modal
				v-if="reserveModalOpen"
				:reservationsState="reservationsState"
				:reserveModalOpen="reserveModalOpen"
				:selectedTimeSlot="selectedTimeSlot"
				@setLoading="toggleQueryLoading"
				@closeConfirmModal="closeReservePopup"
				@refetchTimeSlots="refetchTimeSlots"
			></reservation-confirm-modal>
			<reservation-cancel-modal
				v-if="cancelModalOpen"
				@setLoading="toggleQueryLoading"
				@closeCancelModal="closeCancelModal"
				:cancelModalOpen="cancelModalOpen"
				:selectedTimeSlot="selectedTimeSlot"
				@refetchTimeSlots="refetchTimeSlots"
			></reservation-cancel-modal>
			<reservation-pop-up
				v-if="isReservationModalVisible"
				:showModal="isReservationModalVisible"
				:reservationMessage="reservationMessage"
				:confirmEnabled="reservationConfirmEnabled"
				@closeModal="closeReservePopup"
				@reserveSlots="openReserveModal"
			></reservation-pop-up>
		</ion-content>
	</ion-page>
</template>

<script lang="ts">
import AppHeader from '@/view/components/AppHeader.vue';
import ReservationPopUp from '@/view/components/ReservationPopUp.vue';
import SchedulerLegend from '@/view/components/graphics/SchedulerLegend.vue';
import { computed, onMounted } from "vue";
import {
	alertController,
	IonIcon,
	IonSkeletonText,
	IonToolbar,
	useIonRouter
} from '@ionic/vue';
import {
	chevronBackOutline,
	chevronForwardOutline,
	filter,
	alertCircle,
	alarm
} from 'ionicons/icons';
import { Calendar } from 'v-calendar';
import { defineComponent, ref } from "vue";
import { useStore } from "vuex";
import {
	GET_TIME_SLOTS_QUERY,
	SCHEDULED_TIME_SLOTS_QUERY,
	SLOT_VISIBILITY_QUERY,
	TIME_SLOT_UPDATED_SUB,
	futureDrivingDataSubscription
} from '@/core/api/schedulerApi';
import { useQuery } from '@vue/apollo-composable';
import { createInstanceVMs } from './createTimeSlotVMs';
import { useRoute, useRouter } from 'vue-router';
import Filters from './Filters.vue';
import { toast } from '../../../core/toast/Toast';
import TimeSlotAdjustmentItem from '../../components/TimeSlotAdjustmentItem.vue'
import TimeSlotGranularAdjustmentItem from '../../components/TimeSlotGranularAdjustmentItem.vue'
import ReservationConfirmModal from '../../components/ReservationConfirmModal.vue'
import ReservationCancelModal from '../../components/ReservationCancelModal.vue'
import MDIAlertOctagon from '@/view/components/icons/MDIAlertOctagon.vue';
import ProgressMessage from '@/view/components/ProgressMessage.vue';
import AlertBar from "@/view/components/AlertBar.vue";
import { addMonths, endOfMonth, parseISO, format, isPast, differenceInCalendarDays, isWithinInterval, addDays, startOfMonth } from 'date-fns';
import { formatInTimeZone, utcToZonedTime } from 'date-fns-tz';
import NotificationCard from "@/view/components/cards/NotificationCard.vue";
import { SessionType } from '@/core/SessionType';
import { ContactInfo, Location, School, SessionDetails, TimeSlot, TimeSlotVM, User, Zone } from '@/core/store/store';

export default defineComponent({
	name: "Scheduler",
	components: {
		AppHeader,
		ReservationPopUp,
		ReservationConfirmModal,
		Calendar,
		Filters,
		IonIcon,
		IonSkeletonText,
		IonToolbar,
		MDIAlertOctagon,
		TimeSlotAdjustmentItem,
		TimeSlotGranularAdjustmentItem,
		ReservationCancelModal,
		ProgressMessage,
		AlertBar,
		NotificationCard,
		SchedulerLegend
	},
	setup() {
		const queryLoading = ref(false);
		const store = useStore();
		const route = useRoute();
		const router = useRouter();
		const ionRouter = useIonRouter();

		const selectedDate = ref(route.params.instanceDate as string || null);
		const initialDate = selectedDate.value || formatInTimeZone(new Date(), store.getters.institutionSettings.timeZone, 'yyyy-MM-dd');

		const pretendLocalDate = parseISO(initialDate);
		const startOfViewedMonth = ref(startOfMonth(pretendLocalDate));

		const queriesEnabled = computed(() => store.getters.isAuthenticated);

		const userAppointmentsQuery = useQuery(
			SCHEDULED_TIME_SLOTS_QUERY,
			() => ({
				institutionSlug: store.getters.user.institutions[0],
				userIDs: store.getters.studentIDs
			}),
			{
				fetchPolicy: 'network-only',
				enabled: queriesEnabled.value
			}
		);

		userAppointmentsQuery.onResult(result => {
			if (result?.data?.timeSlotsWithUserSessionInstances) store.commit('setUserAppointmentsData', result.data.timeSlotsWithUserSessionInstances);
		})

		userAppointmentsQuery.subscribeToMore({
			document: TIME_SLOT_UPDATED_SUB,
			variables: {institution: store.getters.user.institutions[0]},
			updateQuery: (prev, {subscriptionData}) => {
				const tmpTimeSlots = [...(prev?.timeSlotsWithUserSessionInstances||[])];
				const incomingTimeSlotContainsStudentIDs = subscriptionData.data?.timeSlotUpdated?.instances?.some(inst => {
					return inst.sessions.some(session => store.getters.studentIDs?.includes(session.userID));
				});
				const index = tmpTimeSlots.findIndex(timeSlot => timeSlot.timeSlotID === subscriptionData.data.timeSlotUpdated.timeSlotID);
				if (index === -1 && incomingTimeSlotContainsStudentIDs) {
					tmpTimeSlots.push(subscriptionData.data.timeSlotUpdated);
				} else if (incomingTimeSlotContainsStudentIDs) {
					tmpTimeSlots[index] = subscriptionData.data.timeSlotUpdated;
				}
				return {timeSlotsWithUserSessionInstances: tmpTimeSlots.filter(ts => ts.instances?.length)};
			}
		});

		const timeSlotQueryVariables = ref({});
		timeSlotQueryVariables.value = {
			institutionSlug: store.getters.user.institutions[0],
			timePeriod: {
				startDate: format(startOfViewedMonth.value, 'yyyy-MM-dd'),
				endDate: format(endOfMonth(startOfViewedMonth.value), 'yyyy-MM-dd')
			}
		};

		const timeSlotQuery = useQuery(
			GET_TIME_SLOTS_QUERY, () => ({
				...timeSlotQueryVariables.value
			}),
			{
				fetchPolicy: 'cache-and-network',
				enabled: queriesEnabled.value
			})
		timeSlotQuery.onError(error => {
			queryLoading.value = false;
			console.error(error);

			toast.error({
				message: 'Calendar details cannot be retrieved at this time',
				duration: 7500,
				position: 'top'
			});
		});

		const selectedUser = computed(() => store.getters.selectedUser ? store.getters.selectedUser : store.getters.user.userID);
		timeSlotQuery.onResult(result => {
			if (result?.data?.timeSlots) store.commit('setTimeSlotsData', result.data.timeSlots);
		})

		timeSlotQuery.subscribeToMore({
			document: TIME_SLOT_UPDATED_SUB,
			variables: {institution: store.getters.user.institutions[0]},
			updateQuery: (prev, {subscriptionData}) => {
				const tmpTimeSlots = [...prev.timeSlots];
				const index = tmpTimeSlots.findIndex(timeSlot => timeSlot.timeSlotID === subscriptionData.data.timeSlotUpdated.timeSlotID);
				if (index === -1) {
					tmpTimeSlots.push(subscriptionData.data.timeSlotUpdated);
				} else {
					if (!subscriptionData?.data?.timeSlotUpdated) {
						tmpTimeSlots.splice(index, 1);
					} else {
						tmpTimeSlots[index] = subscriptionData.data.timeSlotUpdated;
					}
				}
				return {timeSlots: tmpTimeSlots.filter(ts => ts.instances?.length)};
			}
		})
		const timeSlotsQueryResults = computed(() => timeSlotQuery?.result?.value?.timeSlots ?? []);

		const futureDrivingDataSub = futureDrivingDataSubscription(computed(() => ({
			institution: store.getters.institutionSettings.slug,
			studentCourseID: store.getters.selectedUserDrivingStudentCourseID
		})));

		futureDrivingDataSub.onResult(({data}) => {
			store.dispatch('setMandatoryGapSpans', data.futureDrivingDataUpdated);
		})

		const slotVisibilityQuery = useQuery(
			SLOT_VISIBILITY_QUERY, () => ({
				slug: store.getters.user.institutions[0]
			}),
			{
				fetchPolicy: 'no-cache',
				enabled: queriesEnabled.value
			})
		slotVisibilityQuery.onError(error => {
			queryLoading.value = false;
			console.error(error);

			toast.error({
				message: 'Calendar settings cannot be retrieved at this time',
				duration: 7500,
				position: 'top'
			});
		});
		slotVisibilityQuery.onResult(result => {
			if (result?.data?.institutionSettings?.schedulerSettings?.timeSlotVisibility) {
				store.commit('setTimeSlotVisibility', result.data.institutionSettings.schedulerSettings.timeSlotVisibility);
			} else {
				store.commit('setTimeSlotVisibility', 'UNRESTRICTED');
			}
		})

		return {
			chevronBackOutline,
			chevronForwardOutline,
			alarm,
			filter,
			alertCircle,
			darkMode: computed(() => store.state.theme.darkMode),
			ionRouter,
			router,
			store,
			selectedUser,
			slotVisibilityQuery,
			timeSlotQueryVariables,
			timeSlotQuery,
			timeSlotsQueryResults,
			userAppointmentsQuery,
			selectedDate,
			startOfViewedMonth,
			queryLoading
		};
	},
	data() {
		return {
			timeSlotUpdateObserver: null,
			viewingFilters: false,
			filters: {
				instructor: null,
				location: null,
				school: null,
				zones: null,
				sessionType: null,
				noOtherStudents: false
			},
			filterCount: 0,
			filterRestrictions: {
				lockSchool: false,
				lockZones: false,
				hideInstructors: false,
			},
			user: {
				students: []
			},
			isReservationModalVisible: false,
			reservationsState: null,
			timeSlotIDOfSelectedReservations: '',
			reservationMessage: '',
			reservationConfirmEnabled: false,
			reserveModalOpen: false,
			cancelModalOpen: false,
			selectedTimeSlot: null,
		};
	},
	methods: {
		format,
		parseISO,
		toggleQueryLoading(state) {
			this.queryLoading = state;
		},
		refetchTimeSlots() {
			this.timeSlotQuery.refetch();
			this.userAppointmentsQuery.refetch()
			this.slotVisibilityQuery.refetch();
			this.store.dispatch('refetchMandatoryGapSpans');
		},
		openReserveModal() {
			this.reserveModalOpen = true;
		},
		openCancelModal(timeSlot) {
			this.selectedTimeSlot = timeSlot;
			this.cancelModalOpen = true;
		},
		async updateReservationState(reservations, timeSlotID, reservationMessage, reservationConfirmEnabled) {
			this.timeSlotIDOfSelectedReservations = timeSlotID;
			this.selectedTimeSlot = this.timeSlotsOnSelectedDate.find(ts => ts.timeSlotID === timeSlotID);
			this.reservationMessage = reservationMessage;
			this.reservationConfirmEnabled = reservationConfirmEnabled;
			if (!reservations) {
				this.store.dispatch('hideTabFooter', false);
				this.isReservationModalVisible = false;
			} else {
				this.store.dispatch('hideTabFooter', true);
				this.isReservationModalVisible = true;
				this.reservationsState = reservations;
			}
		},
		closeCancelModal() {
			this.cancelModalOpen = false;
		},
		closeReservePopup() {
			this.reserveModalOpen = false;
			this.isReservationModalVisible = false;
			this.store.dispatch('hideTabFooter', false);
			this.reservationsState = null;
			this.timeSlotIDOfSelectedReservations = '';
		},
		async checkOnHold() {
			if (this.store.getters.btwCourseOnHold) {
				const alert = await alertController.create({
						backdropDismiss: false,
						buttons: [
							{
								text: 'Close',
								handler: () => {
									alert.dismiss().then(() => {
										this.router.push('/home');
									});
								}
							}
						],
						cssClass: 'ion-text-center',
						header: 'Administrative Hold',
						message: this.store.getters.onHoldMessage
					});
					alert.present();
			} else {
				//normal nav to scheduler
				this.router.push('/scheduler');
			}
		},
		setDefaultFilters() {
			this.filters = {
				instructor: null,
				location: null,
				school: null,
				zones: null,
				sessionType: null,
				noOtherStudents: false
			};

			const currentUserID = this.store.getters.selectedUser;
			if (!currentUserID) {
				return;
			}
			const currentUser = this.store.getters.students.find(s => s.userID === currentUserID);
			this.filterRestrictions.hideInstructors = this.store.getters.institutionSettings.schedulerSettings?.hideInstructors;
			switch (this.store.getters.timeSlotVisibility) {
				case 'SCHOOL_ZONE_RESTRICTED':
					if (currentUser) {
						if (currentUser.school) {
							this.filterRestrictions.lockSchool = true;
						}
						if (currentUser.zones) {
							this.filterRestrictions.lockZones = true;
						}
					}
					// fallthrough to
				case 'SCHOOL_ZONE_UNRESTRICTED':
					if (currentUser) {
						if (currentUser.school) {
							this.filters.school = this.schools.find(s => s.locationID == currentUser.school.locationID)
						} else {
							this.filters.school = null;
						}
						if (currentUser.zones) {
							this.filters.zones = this.zones.filter(slotZone => currentUser.zones.find(userZone => userZone.id == slotZone.id))
						} else {
							this.filters.zones = null;
						}
					} else {
						console.error('setDefaultFilters:  currentUser not found')
					}
					break;
				case 'UNRESTRICTED':
				case '':
					this.filters.school = null;
					this.filters.zones = null;
					break;
				default:
					console.error('setDefaultFilters(): undefined timeSlotVisibility', this.store.getters.timeSlotVisibility)
			}

			this.updateActiveFiltersBadge();
		},
		async validateUserPermit() {
			if (!this.store.getters.selectedUser) {
				return;
			}
			const isValidUser = this.store.getters.validSchedulerUser;
			if (!isValidUser) {
				this.showPermitAlert(this.store.getters.selectedUser);
			}
		},
		disableTimeSlotButton(timeSlot) {
			const userAppointmentOnGroup = timeSlot.sessionGroup.some(sesh => sesh.userID === this.selectedUser);

			if (isPast(parseISO(timeSlot.date))) {
				return true;
			}

			if (userAppointmentOnGroup) {
				return false;
			}

			if (this.isInWaitingPeriod && !userAppointmentOnGroup || this.timeSlotsOnSelectedDate.find(ts => ts.isScheduled) || this.futureAppointmentsLocked.locked) {
				return true;
			}
			return false;
		},
		hideTimeSlot(timeSlot) {
			if (timeSlot.isScheduled) return false;
			if (!timeSlot.sessionGroup.every(session => !session.userID)) {
				return true;
			}
			return false;
		},
		updateActiveFiltersBadge() {
			let counter = 0;
			for (const filterVal of Object.values(this.filters)) {
				if (filterVal) counter++;
			}
			this.filterCount = counter;
		},
		applyFilters(filters) {
			this.filters = filters;
			this.updateActiveFiltersBadge();
			this.viewingFilters = false;
		},
		resetFilters() {
			this.setDefaultFilters();
			this.viewingFilters = false;
		},
		isBetweenTimeStamps(timeStamp, range) {
			return ((timeStamp > range.start) && (timeStamp < range.end)) ? true : false;
		},
		selectDay(date) {
			this.selectedDate = date.id;
		},
		async toNewPage({month, year}) {
			const newStartOfViewedMonth = new Date(year, month-1);
			if (this.startOfViewedMonth.getTime() !== newStartOfViewedMonth.getTime()) {
				this.selectedDate = null;
			}

			this.startOfViewedMonth = newStartOfViewedMonth;
			this.timeSlotQueryVariables = {
				institutionSlug: this.store.getters.user.institutions[0],
				timePeriod: {
					startDate: format(newStartOfViewedMonth, 'yyyy-MM-dd'),
					endDate: format(endOfMonth(newStartOfViewedMonth), 'yyyy-MM-dd')
				}
			}
		},
		async showPermitAlert(userID) {
			const alert = await alertController.create({
				backdropDismiss: false,
				buttons: [
					{
						text: 'Cancel',
						handler: () => {
							alert.dismiss().then(() => {
								this.ionRouter.navigate('/home', 'back', 'replace');
							});
						}
					},
					{
						text: 'Edit Details',
						handler: () => {
							alert.dismiss().then(() => {
								this.ionRouter.navigate('/home', 'forward', 'replace');
								this.store.commit('showSettingsModal', {type: 'userDetails', user: userID});
							});
						}
					}
				],
				cssClass: 'ion-text-center',
				header: 'Missing Permit Details',
				message: this.isParent ? 'To schedule an appointment for this student, please select their profile under ‘Settings / My Students’ and enter their permit information to verify that they are legally allowed to be behind the wheel.' : 'Before you can schedule drive or observation appointments you will need to enter your permit information to verify that you are legally allowed to be behind the wheel.'
			});
			alert.present();
		},
		async showSelectStudentAlert() {
			const user = this.store.getters.user;
			let alertInputs = [];

			for (let i = 0; i < user.students.length; i++) {
				alertInputs.push({
					type: 'radio',
					label: user.students[i].firstName + ' ' + user.students[i].lastName,
					value: user.students[i].userID,
					checked: (i === 0) ? true : false
				});
			}

			const alert = await alertController.create({
				backdropDismiss: false,
				buttons: [
					{
						text: 'Select',
						handler: async (value) => {
							this.updateSchedulerUser(value)
						}
					}
				],
				cssClass: 'ion-text-center',
				header: 'Select Student',
				inputs: alertInputs
			});
			alert.present();
		},
		async updateSchedulerUser(userID) {
			const studentsArray = [
				...this.store.getters.user.students
			];
			let selectedStudent = studentsArray.find(s => s.userID === userID);
			if(selectedStudent) {
				if(selectedStudent.permitNumber && selectedStudent.permitIssued && selectedStudent.permitIssuingState) {
					this.store.commit('setSelectedUser', userID);
				} else {
					// Reset the the selectedUser
					this.store.commit('setSelectedUser', '');
					if(userID) {
						this.showPermitAlert(userID);
					}
				}
			}
		}
	},
	watch: {
		selectedUser() {
			// TODO: Review how we are managing this check so permit alert doesn't show on login screen.
			if(this.store.getters.isAuthenticated !== false && this.router.currentRoute.value.path === '/scheduler') {
				this.checkOnHold();
				this.validateUserPermit();
			}
		},
		initialized() {
			if(this.store.getters.isAuthenticated !== false && this.router.currentRoute.value.path === '/scheduler') {
				this.validateUserPermit();
			}
		},
		selectedDate() {
			this.closeReservePopup();
		}
	},
	computed: {
		allTimeSlots() {
			return createInstanceVMs(this.timeSlotsQueryResults||[], this.selectedUser);
		},
		filteredTimeSlots(): TimeSlotVM[] {
			const {
				instructor,
				location,
				school,
				zones,
				sessionType,
				noOtherStudents
			} = this.filters;

			return this.allTimeSlots.filter((timeSlot: TimeSlotVM) => {
				if (timeSlot.instance.sessions.some((session) => session.userID === this.selectedUser)) return true;

				if (instructor && !timeSlot.hasInstructor(instructor)) return false;
				if (location && !timeSlot.isAtLocation(location)) return false;
				if (!timeSlot.matchesSchoolOrZone(school, zones)) return false;
				if (noOtherStudents && timeSlot.hasStudents) return false;
				if (sessionType === SessionType.drive && !timeSlot.hasOpenDriveSessions) return false;
				if (sessionType === SessionType.observe && !timeSlot.hasOpenObserveSessions) return false;
				return true;
			});
		},
		fullTimeSlots() {
			return this.filteredTimeSlots.filter((timeSlot: TimeSlotVM) => {
				if(!isPast(parseISO(timeSlot.instance.date))) {
					return !timeSlot.isOpen;
				}
			});
		},
		openTimeSlots() {
			return this.filteredTimeSlots.filter((timeSlot) => !timeSlot.isPast && timeSlot.isOpen);
		},
		scheduledTimeSlots(): TimeSlotVM[] {
			return this.allTimeSlots.filter((timeSlot) => timeSlot.isScheduled);
		},
		scheduledPassedTimeSlots() {
			return this.scheduledTimeSlots.filter((timeSlot) => timeSlot.isPast);
		},
		userHasTimeSlotOnSelectedDate() {
			return this.timeSlotsOnSelectedDate.some((timeSlot) => timeSlot.isScheduled);
		},
		timeSlotsOnSelectedDate() {
			return this.filteredTimeSlots.filter((timeSlot) => timeSlot.instance.date === this.selectedDate);
		},
		futureAppointmentsLocked() {
			return this.store.getters.futureAppointmentsLocked;
		},
		groupedSessions() {
			const sessionTimeSlotGroupings: Array<{sessionGroup: SessionDetails[], timeSlot: TimeSlotVM}> = [];

			this.timeSlotsOnSelectedDate.forEach(timeSlotOnDate => {
				timeSlotOnDate.instance.groupedSessions.forEach(gs => {
					sessionTimeSlotGroupings.push({
						timeSlot: timeSlotOnDate,
						sessionGroup: gs.sessions
					})
				})
			});
			sessionTimeSlotGroupings.sort((a, b) => {
				return Number(b.sessionGroup[0].userID === this.selectedUser) - Number(a.sessionGroup[0].userID === this.selectedUser) || Number(b.sessionGroup.every(sesh => !sesh.userID)) - Number(a.sessionGroup.every(sesh => !sesh.userID));
			})

			return sessionTimeSlotGroupings;
		},
		noAvailableAppointments() {
			return !this.loading && this.selectedDate && !this.timeSlotsOnSelectedDate.some(ts => ts.isOpen || ts.isScheduled)
		},
		isInWaitingPeriod() {
			return !this.loading && this.mandatoryGapDates.find(dates => dates.startDate <= this.selectedDate && dates.endDate >= this.selectedDate) && !this.timeSlotsOnSelectedDate.find(ts => ts.isScheduled);
		},
		isParent() {
			return this.store.getters.user.roles?.some(({slug}) => slug === 'parent') || false;
		},
		loading() {
			return this.queryLoading;
		},
		mandatoryGapDates() {
			const currentUser: User = this.store.getters.students.find(s => s.userID === this.selectedUser);
			const studentBTWCourse = currentUser?.courses?.find(course => { return ( course.studentCourseStatus === 'registered' && course.course.categories.some(category => category.slug === 'behind-the-wheel')); });
			return studentBTWCourse?.futureDrivingData?.mandatoryGapSpans?.map((span) => {
				const start = parseISO(span.start)
				const end = parseISO(span.end);
				return {
					startDate: span.start,
					endDate: span.end,
					start,
					end,
					span: differenceInCalendarDays(end, start) + 1
				}
			}) ?? [];
		},
		observeDisabled() {
			return this.store.getters.observeDisabled;
		},
		openingsInGapPeriod() {
			return this.filteredTimeSlots.filter(({instance}) => this.mandatoryGapDates.some((interval) => isWithinInterval(parseISO(instance.date), interval)));
		},
		gapFromMostFutureScheduledAppointment() {
			return format(addDays(this.mandatoryGapDates[this.mandatoryGapDates.length-1].end, 1), 'MM/dd/yyyy');
		},
		calendarAttributes() {
			let calAttrs = [];

			if (!this.loading) {
				calAttrs =  [
					{
						key: 'today',
						dates: new Date()
					},
					{
						key: 'scheduledPassed',
						highlight: {
							contentClass: 'event-on-calendar',
							fillMode: 'solid',
							color: 'green'
						},
						dates: this.scheduledPassedTimeSlots.map(({instance}) => parseISO(instance.date)),
						order: 6
					},
					{
						key: 'scheduled',
						highlight: {
							contentClass: 'event-on-calendar',
							fillMode: 'solid',
							color: 'green'
						},
						dates: this.scheduledTimeSlots.map(({instance}) => parseISO(instance.date)),
						order: 5
					},
					{
						key: 'fullTimeSlots',
						highlight: {
							contentClass: 'event-on-calendar',
							fillMode: 'solid',
							color: 'gray'
						},
						dates: this.fullTimeSlots.map(({instance}) => parseISO(instance.date)),
						order: 2
					},
					{
						key: 'openingsInGapPeriod',
						highlight: {
							contentClass: 'event-on-calendar',
							fillMode: 'solid',
							color: 'gray'
						},
						dates: this.openingsInGapPeriod.map(({instance}) => parseISO(instance.date)),
						order: 4
					},
					{
						key: 'open',
						highlight: {
							contentClass: 'event-on-calendar',
							fillMode: 'solid'
						},
						dates: this.openTimeSlots.map(({instance}) => parseISO(instance.date)),
						order: 3
					},
					{
						key: 'blocked',
						highlight: {
							start: {
								color: 'red',
								fillMode: 'light'
							},
							base: {
								color: 'red',
								fillMode: 'light'
							},
							end: {
								color: 'red',
								fillMode: 'light'
							},
						},
						dates: this.mandatoryGapDates,
						order: 1
					}
				];
			}

			return calAttrs;
		},
		instructors() {
			const uniqueInstructors = new Map<String, ContactInfo>();

			for (const timeSlot of this.allTimeSlots) {
				uniqueInstructors.set(timeSlot.instructor.userID, timeSlot.instructor);
			}

			return Array.from(uniqueInstructors.values())
		},
		locations(): Location[] {
			const uniqueLocations = new Map();

			for (const { location, instance } of this.allTimeSlots) {
				instance.sessions.forEach(sesh => {
					if (sesh.customPickupLocation) {
						uniqueLocations.set(sesh.customPickupLocation.name + sesh.customPickupLocation.address, sesh.customPickupLocation);
					}
				})
				if (location && location.locationID !== 'none') {
					uniqueLocations.set(location.name + location.address, location);
				}
			}

			return Array.from(uniqueLocations.values())
		},
		schools() {
			const uniqueSchools = new Map<String, School>();

			const currentUser: User = this.store.getters.students.find((s: User) => s.userID === this.store.getters.selectedUser);

			if (currentUser && currentUser.school) {
				uniqueSchools.set(currentUser.school.locationID, currentUser.school)
			}

			for (var { schools } of this.allTimeSlots) {
				if (schools) {
					for (var school of schools) {
						uniqueSchools.set(school.locationID, school);
					}
				}
			}

			return Array.from(uniqueSchools.values())
		},
		zones() {
			const uniqueZones = new Map<String, Zone>();

			const currentUser: User = this.store.getters.students.find((s: User) => s.userID === this.store.getters.selectedUser);

			if (currentUser && currentUser.zones) {
				for (var zone of currentUser.zones) {
					uniqueZones.set(zone.id, zone)
				}
			}

			for (var { zones } of this.allTimeSlots) {
				if (zones) {
					for (var zone of zones) {
						uniqueZones.set(zone.id, zone);
					}
				}
			}

			return Array.from(uniqueZones.values())
		},
		initialized() {
			return this.store.getters.initialized;
		},
		institutionTZ() {
			return this.store.getters.institutionSettings?.timeZone ?? 'America/Chicago';
		},
		eLearningDaysFromLockDate() {
			return this.store.getters.eLearningDaysFromLockDate;
		},
		showeLearningExtensionWarning() {
			return this.store.getters.showeLearningExtensionWarning;
		},
		classroomUserProgress() {
			return this.store.getters.studentsClassroomProgress.find(scp => scp.userID === this.store.getters.selectedUser)?.classroomUserProgress || false;
		},
		currentUser() {
			return this.store.getters.students.find(s => s.userID === this.store.getters.selectedUser);
		},
		notificationsForScheduler() {
			return this.store.getters.notifications.filter(({showOnScheduler}) => showOnScheduler);
		}
	},
	async ionViewWillEnter() {
		this.queryLoading = true;
		this.validateUserPermit();
		this.timeSlotQuery.start();
		await Promise.all([
			this.timeSlotQuery.refetch(),
			this.slotVisibilityQuery.refetch(),
			this.userAppointmentsQuery.refetch()
		]);
		this.setDefaultFilters()
		this.queryLoading = false;
	},
	ionViewWillLeave() {
		this.timeSlotQuery.stop();
	}
});
</script>

<style lang="scss">
	.vc-pane-container {
		border-bottom: 1px solid var(--ion-color-secondary-shade);
	}

	.vc-container {
		border: none !important;
		border-radius: 0 !important;
	}

	.scrollable-content {
		height: calc(100% - 325px);
		overflow-y: scroll;

		&::-webkit-scrollbar {
			-webkit-appearance: none;
			width: 7px;
		}
		&::-webkit-scrollbar-thumb {
			border-radius: 4px;
			background-color: rgba(0, 0, 0, .5);
			-webkit-box-shadow: 0 0 1px rgba(255, 255, 255, .5);
		}
	}

	.margin-bottom-200 {
		margin-bottom: 200px;
	}

	ion-list {
		background: var(--ion-color-secondary);
	}

.is-today.in-month>span{

	position: relative;

	&:before {
		background: radial-gradient(circle, rgba(43,64,81,0) 47%, rgba(43,64,81,1) 50%, rgba(43,64,81,0) 53%);
		content: '';
		height: 46px;
		left: -9px;
		position: absolute;
		top: -9px;
		width: 46px;
	}

	.vc-dots {
		transform: translateY(2px);
	}
}

.md ion-content ion-toolbar {
	--background: var(--surface, #FFFFFF);
	box-shadow: var(--box-shadow);
	border-bottom: 1px solid var(--ion-color-secondary-shade);
}
</style>

<style lang="scss" scoped>
.mdi-icon {
	margin-right: 32px;
	fill: #FFFFFF;
	height: 25px;
	width: 25px;
}
.filter-button {
	--box-shadow: none;
	--padding-bottom: 0;
	--padding-end: 0;
	--padding-start: 0;
	--padding-top: 0;
	--background: var(--ion-color-light);
	margin-right: 20px;
	height: 32px;


	&__icon {
		display: flex;
		align-items: center;
		justify-content: center;
		background-color: var(--ion-color-primary);
		height: 100%;
		border-radius: 155px;
		height: 30px;
		width: 30px;

	}

	&__text {
		color: var(--ion-color-light);
		background-color: var(--ion-color-primary);
		padding: 11% 20px;
		text-align: center;
		border-radius: 0 155px 155px 0;
		width: 40px;
		height: 30px;
	}

	&--active {
		border: 2px solid var(--ion-color-light);
		transform: translateX(10px);
		height: 34px;
		width: 34px;

	}
}

ion-list.timeslot-list {
	background: var(--ion-background-color, #EFF0F2);
}
</style>