import { Module } from 'vuex';
import { v4 as uuidv4 } from 'uuid';
import { Preferences } from '@capacitor/preferences';
import {
	readFragment,
	writeFragment,
	UPDATE_PROGRESS_MUTATION,
	UPDATE_APPOINTMENTS_MUTATION,
	UPDATE_VEHICLE_HISTORY_MUTATION,
	updateFragment,
	SWITCH_USER_TO_DRIVING_SESSION_MUTATION,
	ADD_USER_TO_SESSION_MUTATION
} from '../../api/drivingApi';

import { apolloClient } from '../../apollo/apolloClient';

import { Network } from '@capacitor/network';

export type syncDataStatus = 'queued' | 'syncing' | 'success' | 'error';

export type SyncData = {
	localId: string;
	id: string;
	status: syncDataStatus;
	lastUpdate: number;
	orderTimestamp: number;
	dataType: 'switchUserToDrivingSession' | 'skillProgress' | 'addUserToSession' | 'updateAppointments' | 'vehicleHistory';
	rawData: any;
}

export type InstructorCompletedBy = {
	userID: string;
	firstName: string;
	lastName: string;
}

export class SkillProgress {
	// id is the skillID
	id: string;
	score: number;
	studentCourseID: string;
	userID: string;
	institution: string;
	completedDate: number;
	completedBy: string | InstructorCompletedBy;
	attempts: SkillAttempt[];
	__typename = 'SkillProgress';

	constructor(skillProgress: SkillProgress) {
		this.id = skillProgress.id;
		this.score = skillProgress.score;
		this.studentCourseID = skillProgress.studentCourseID;
		this.userID = skillProgress.userID;
		this.institution = skillProgress.institution;
		this.completedDate = skillProgress.completedDate;
		this.completedBy = skillProgress.completedBy;
		this.attempts = skillProgress.attempts;
		this.__typename = 'SkillProgress';
	}

	mergeIncomingAttempts?(incomingAttempts: SkillAttempt[]) {
		// TODO: Consider sanitation of duplicate id such as the following example.
		// [{ id: '1' },{ id: '1'}]

		// Update existing items.
		let unmodifiedAttempts = this.attempts.filter((attempt) => {
			return incomingAttempts.find((incomingAttempt) => attempt.id !== incomingAttempt.id);
		});

		// Add in our new/updated skillAttempts.
		let updatedAttempts = unmodifiedAttempts.concat(incomingAttempts);

		// Delete missing items.
		this.attempts = updatedAttempts.filter(attempt => !attempt.delete).map((attempt) => {
			return new SkillAttempt(attempt);
		});

		return this;
	}

	// If the attempt is the most recent attempt, set the completed date.
	// If the attempt does not have the completedDate property, ignore it.
	// If the attempt passes the completedDate as null, unset the completedDate on the skill progress item.
	setCompletedAndScoreFromMostRecentAttempt?(mostRecentAttempt: SkillAttempt) {
		if (mostRecentAttempt.completedDate) {
			this.completedDate = mostRecentAttempt.completedDate;
			this.completedBy = mostRecentAttempt.instructor;
		} else {
			this.completedDate = null;
			this.completedBy = null;
		}

		this.score = mostRecentAttempt.score;
		return this;
	}
}

export class SkillAttempt {
	id: string;
	date: number;
	success: boolean;
	completedDate: number;
	score: number;
	delete: boolean;
	instructor: string | InstructorCompletedBy;
	oInstructor: InstructorCompletedBy;
	instanceID: string;
	sessionID: string;
	skillSetID: string | null;
	note: string;
	feedbackItems: FeedbackItem[];

	__typename = 'SkillAttempt';

	constructor(skillAttempt: SkillAttempt) {
		this.id = skillAttempt.id;
		this.date = skillAttempt.date;
		this.success = skillAttempt.success;
		this.completedDate = skillAttempt.completedDate;
		this.score = skillAttempt.score;
		this.instructor = skillAttempt.instructor;
		this.instanceID = skillAttempt.instanceID;
		this.sessionID = skillAttempt.sessionID;
		this.skillSetID = skillAttempt.skillSetID || null;
		this.note = skillAttempt.note || '';
		this.feedbackItems = skillAttempt.feedbackItems;
		this.__typename = 'SkillAttempt';
	}
}

export type FeedbackItem = {
	id: string;
	feedbackMessage: string;
	__typename: 'FeedbackItem';
}


export type syncDataMap = Map<string, SyncData>;

export type State = {
	syncData: syncDataMap | null;
}

function setStoreSyncDataByType(storageKey: string, storageDataValue: any, syncData: syncDataMap) {
	switch (storageDataValue.dataType) {
		case 'skillProgress':
			const skillProgress = new SkillProgress(storageDataValue.rawData);
			syncData.set(storageKey, {
				...storageDataValue,
				rawData: skillProgress
			});
			break;
		case 'switchUserToDrivingSession':
			console.log('TODO: switchUserToDrivingSession');
			// TODO: TICKET_NEEDED add this
			// raw data will be the request variables
			// still a map in case there are multiple
			// operation name will be the mutation name
			break;
		case 'addUserToSession':
			// TODO: TICKET_NEEDED add this?
			console.log('TODO: addUserToSession');

			break;
		case 'updateAppointments':
			syncData.set(storageKey, {
				...storageDataValue,
				rawData: storageDataValue.rawData
			});
			break;
		case 'vehicleHistory':
			syncData.set(storageKey, {
				...storageDataValue,
				rawData: storageDataValue.rawData
			});
			break;
		default: throw new Error('Unknown data type');
	}
}

export const sync: Module<State, State> = {
	state() {
		return {
			syncData: null
		};
	},
	getters: {
		async getSyncData(state) {
			return state.syncData;
		},
		getSyncDataById: (state) => (id: string) => {
			if (!state.syncData) {
				return null;
			}
			return state.syncData[id];
		},
		getSyncDataByStatus: (state) => (status: syncDataStatus) => {
			if (!state.syncData) {
				return null;
			}
			return Array.from(state.syncData.values()).filter((syncData) => syncData.status === status);
		},
	},
	actions: {
		async adjustCacheWithSyncData(store: any) {
			// get sync data from local storage;
			const syncData = await Preferences.get({key: 'syncData'});
			if (!syncData?.value) {
				return;
			}
			const syncDataMap: Record<string, SyncData> = JSON.parse(syncData.value);
			for (const [key, value] of Object.entries(syncDataMap)) {
				const updateType = value.dataType;
				const rawData = value.rawData;
				switch (updateType) {
					case 'switchUserToDrivingSession': {
						// update cache
						const driveSession = readFragment(
							`SessionDetails:{"sessionID":"${rawData.existingDriveData.currentSessionID}"}`
						);
						const observationSession = readFragment(
							`SessionDetails:{"sessionID":"${rawData.switchToDriveData.currentSessionID}"}`
						);
						if ((driveSession.status && driveSession.status !== 'SCHEDULED') ||
							(observationSession.status && observationSession.status !== 'SCHEDULED')) {
							throw new Error('Completed sessions cannot be swapped.');
						}
						writeFragment(
							`SessionDetails:{"sessionID":"${rawData.switchToDriveData.currentSessionID}"}`,
							{
								...observationSession,
								userID: driveSession.userID || null,
								studentCourseID: driveSession.studentCourseID ?? null,
								note: driveSession.note ?? null,
								studentInstructorAppData: driveSession.studentInstructorAppData ?? null,
							}
						);
						writeFragment(
							`SessionDetails:{"sessionID":"${rawData.existingDriveData.currentSessionID}"}`,
							{
								...driveSession,
								userID: observationSession.userID || null,
								note: observationSession.note ?? null,
								studentCourseID: observationSession.studentCourseID ?? null,
								studentInstructorAppData: observationSession.studentInstructorAppData ?? null,
							}
						)

						break;
					}
					case 'addUserToSession': {
						const userFromCache = readFragment(
							`SchedulerUser:{"userID":"${rawData.addToSessionData.userID}"}`
						)
						const studentInstructorAppDataFragment = readFragment(`StudentInstructorAppData:{"id":"${rawData.addToSessionData.studentCourseID}"}`);

						updateFragment(`SessionDetails:{"sessionID":"${rawData.addToSessionData.currentSessionID}"}`, {
							userID: userFromCache.userID,
							studentCourseID: userFromCache.studentCourseID,
							note: '',
							status: 'SCHEDULED',
							studentInstructorAppData: {
								id: studentInstructorAppDataFragment?.id || userFromCache.studentCourseID,
								user: {
									userID: userFromCache.userID,
									firstName: userFromCache.firstName,
									lastName: userFromCache.lastName,
									birthDate: userFromCache.birthDate,
									phone: userFromCache.phone,
									email: userFromCache.email,
									correctiveLensesRequired: userFromCache.correctiveLensesRequired,
									notes: studentInstructorAppDataFragment?.notes || [],
								},
								studentCourse: {
									course: {
										requiredDriveHours: studentInstructorAppDataFragment?.studentCourse?.course?.requiredDriveHours || null
									},
									studentCourseID: studentInstructorAppDataFragment?.studentCourse?.studentCourseID || userFromCache.studentCourseID,
									earliestDriversTestDate: studentInstructorAppDataFragment?.studentCourse?.earliestDriversTestDate || null,
									driveHoursCompleted: studentInstructorAppDataFragment?.studentCourse?.driveHoursCompleted || userFromCache?.driveHoursCompleted || 0,
									observationHoursCompleted: studentInstructorAppDataFragment?.studentCourse?.observationHoursCompleted || userFromCache?.observationHoursCompleted || 0,
									studentCourseStatus: studentInstructorAppDataFragment?.studentCourse?.studentCourseStatus || userFromCache.studentCourseStatus,
								},
								studentSkillProgress: studentInstructorAppDataFragment?.studentSkillProgress || [],
								completedDriveSessions: studentInstructorAppDataFragment?.completedDriveSessions || [],
								scheduledSessions: studentInstructorAppDataFragment?.scheduledSessions || [],
								balanceDue: studentInstructorAppDataFragment?.balanceDue || 0,
								__typename: 'StudentInstructorAppData',
							},
							__typename: 'SessionDetails',
						});
						break;
					}
					case 'updateAppointments': {
						for (const session of rawData.sessions) {
							// update cache with only the properties passed in
							updateFragment(`SessionDetails:{"sessionID":"${session.sessionID}"}`, {status: session.status, note: session.note});
						}
						break;
					}
					case 'vehicleHistory': {
						updateFragment(`Vehicle:{"vehicleID":"${rawData.vehicleHistory.vehicleID}"}`, {currentMileage: rawData.vehicleHistory.endingMileage});
						break;
					}
					default: continue;
				}
			}
		},
		getCurrentSkillProgressStateFromLocalStorage: (store, {progressKey}: {progressKey: string}) => {
			try {
				const currentSkillProgress = localStorage.getItem(progressKey) ? JSON.parse(localStorage.getItem(progressKey)) : null;
				return currentSkillProgress;
			} catch(e) {
				console.error(e);
			}
		},
		async removeAddedSkillAttempts(store) {
			try {
				const addedAttemptsString = await Preferences.get({key:'addedSkillAttempts'});
				const addedAttemptsJSON = addedAttemptsString?.value ? JSON.parse(addedAttemptsString.value) : null;

				if (addedAttemptsJSON) {
					for (const [key, value] of Object.entries(addedAttemptsJSON)) {
						if (Array.isArray(value)) {
							for (const attempt of value) {
								apolloClient.cache.evict({id: attempt});
							}
						}
					}
				}

				await Preferences.remove({key:'addedSkillAttempts'});
			} catch(e) {
				console.error(e);
			}
		},
		async addSkillAttemptToLocalProgress(store, {userID, studentCourseID, skillID, skillAttempt}: {userID: string, studentCourseID: string, skillID: string, skillAttempt: any}) {

			try {
				const skillProgressFragmentID = `SkillProgress:{"id":"${skillID}","studentCourseID":"${studentCourseID}"}`;
				const appDataFragmentID = `StudentInstructorAppData:{"id":"${studentCourseID}"}`;

				const addedAttempts = await Preferences.get({key:'addedSkillAttempts'});
				const addedAttemptsJSON = addedAttempts?.value ? JSON.parse(addedAttempts.value) : {[studentCourseID]: []};
				if (!addedAttemptsJSON[studentCourseID]) {
					addedAttemptsJSON[studentCourseID] = [];
				}
				addedAttemptsJSON[studentCourseID].push(`SkillAttempt:${skillAttempt.id}`);
				await Preferences.set({key:'addedSkillAttempts', value: JSON.stringify(addedAttemptsJSON)});

				const modifiedSkillProgress = await Preferences.get({key:'modifiedSkillProgress'});
				const modifiedSkillProgressJSON = modifiedSkillProgress?.value ? JSON.parse(modifiedSkillProgress.value) : {[studentCourseID]: []};
				if (!modifiedSkillProgressJSON[studentCourseID]) {
					modifiedSkillProgressJSON[studentCourseID] = [];
				}
				modifiedSkillProgressJSON[studentCourseID] = [...new Set([...modifiedSkillProgressJSON[studentCourseID], skillProgressFragmentID])];
				await Preferences.set({key:'modifiedSkillProgress', value: JSON.stringify(modifiedSkillProgressJSON)});

				const incomingAttempt = new SkillAttempt(skillAttempt);

				// If we are deleting the attempt set it on the new SkillAttempt.
				if (skillAttempt.delete) {
					incomingAttempt.delete = true;

					// TODO: Figure out better way to not force reactivity by removing item and then re-adding it.
					apolloClient.cache.evict({
						id: `SkillAttempt:${skillAttempt.id}`
					});
				}
				if (incomingAttempt.instructor === store.getters.user.userID) {
					incomingAttempt.instructor = {
						userID: store.getters.user.userID,
						firstName: store.getters.user.firstName,
						lastName: store.getters.user.lastName,
					}
				}

				let skillProgressFragment = readFragment(skillProgressFragmentID);

				if (!skillProgressFragment) {
					skillProgressFragment = new SkillProgress({
						id: skillID,
						score: skillAttempt.score,
						studentCourseID,
						userID,
						institution: store.getters.user.institutions?.[0],
						attempts: [incomingAttempt],
						completedDate: null,
						completedBy: null,
						__typename: 'SkillProgress'
					});
					skillProgressFragment.setCompletedAndScoreFromMostRecentAttempt(incomingAttempt);
					skillProgressFragment.__typename = 'SkillProgress';
				} else {
					skillProgressFragment = new SkillProgress(skillProgressFragment);
					skillProgressFragment.mergeIncomingAttempts([incomingAttempt]).setCompletedAndScoreFromMostRecentAttempt(incomingAttempt);
				}
				writeFragment(skillProgressFragmentID, skillProgressFragment);

				const instructorAppDataFragment = readFragment(appDataFragmentID);
				const foundSkillProgressItem = instructorAppDataFragment?.studentSkillProgress?.find((s: SkillProgress) => {
					return s.id === skillID
				});
				if (!foundSkillProgressItem) {
					const mergedSkillProgress = [];
					if (instructorAppDataFragment?.studentSkillProgress) {
						mergedSkillProgress.push(...instructorAppDataFragment.studentSkillProgress);
					}
					mergedSkillProgress.push(skillProgressFragment);
					writeFragment(appDataFragmentID, {
						...instructorAppDataFragment,
						studentSkillProgress: mergedSkillProgress
					});
				}

				Preferences.set({
					key: `${skillProgressFragmentID}::${skillAttempt.sessionID}`,
					value: JSON.stringify(skillProgressFragment)
				});
			} catch(e) {
				console.error(e);
			}
		},
		async clearLocalProgressBySessionID(store, {sessionID}: {sessionID: string}) {
			try {
				const keysResult = await Preferences.keys();

				for (const key of keysResult.keys) {
					if (key.includes(`::${sessionID}`)) {
						await Preferences.remove({key});
					}
				}
			} catch(e) {
				console.error(e);
			}
		},
		async captureInitialCacheState(store, {sessionID}: {sessionID: string}) {
			const sessionDetailCacheID = `SessionDetails:{"sessionID":"${sessionID}"}`;
			const currentSessionDetail = readFragment(sessionDetailCacheID);

			await Preferences.set({ key: 'initialSessionState', value: JSON.stringify(currentSessionDetail) });
		},
		async clearInitialCacheState(store, {sessionID}: {sessionID: string}) {
			await Preferences.remove({ key: 'initialSessionState' });
			const allKeys = await Preferences.keys();
			for (const key of allKeys.keys) {
				if (key.includes(`::${sessionID}`)) {
					await Preferences.remove({key});
				}
			}
		},
		async revertCacheState(store, {sessionID}: {sessionID: string}) {
			const initialSessionState = await Preferences.get({ key: 'initialSessionState' });

			if (initialSessionState?.value) {
				const initialSessionStateJSON = JSON.parse(initialSessionState.value);
				const sessionDetailCacheID = `SessionDetails:{"sessionID":"${sessionID}"}`;

				const currentSessionDetail = readFragment(sessionDetailCacheID);
				if (currentSessionDetail?.studentInstructorAppData) {
					for (const skillProgressItem of currentSessionDetail.studentInstructorAppData.studentSkillProgress) {
						const skillProgressFragmentID = `SkillProgress:{"id":"${skillProgressItem.id}","studentCourseID":"${currentSessionDetail.studentInstructorAppData.id}"}`;
						const readCacheSkillProgress = readFragment(skillProgressFragmentID);
						const initialProgressItemsIDs = initialSessionStateJSON?.studentInstructorAppData?.studentSkillProgress.map((s: SkillProgress) => {
							return s.id;
						});
						if (readCacheSkillProgress && !initialProgressItemsIDs.includes(readCacheSkillProgress.id)) {
							apolloClient.cache.evict({ id: skillProgressFragmentID });
						}
					}
				}
				writeFragment(sessionDetailCacheID, initialSessionStateJSON);
				apolloClient.cache.gc();
			} else {
				await store.dispatch('clearInitialCacheState', {sessionID});
			}
		},
		async applyActiveSessionStorageToCache(store, {sessionID}: {sessionID: string}) {
			const keysResult = await Preferences.keys();
			if (keysResult.keys.length) {
				for (const key of keysResult.keys) {
					if (key.includes(`::${sessionID}`)) {
						const [fragmentID, __] = key.split('::');
						const preferenceValue = await Preferences.get({key});
						const cacheValue = JSON.parse(preferenceValue.value);
						const appDataFragmentID = `StudentInstructorAppData:{"id":"${cacheValue.studentCourseID}"}`;
						const instructorAppDataFragment = readFragment(appDataFragmentID);
						if (!instructorAppDataFragment?.studentSkillProgress?.find((s: SkillProgress) => {
							return s.id === cacheValue.id
						})) {
							writeFragment(appDataFragmentID, {
								...instructorAppDataFragment,
								studentSkillProgress: [
									...instructorAppDataFragment.studentSkillProgress,
									cacheValue
								]
							});
						}

						writeFragment(fragmentID, cacheValue);
					}
				}
			}
		},
		setupOfflineListener({commit, dispatch}) {
			Network.addListener('networkStatusChange', async (status) => {
				if (status?.connected) {
					await dispatch('runNetworkRequests');
				}
			});
		},
		async makeNetworkCall({commit, dispatch}, {key, value}: {key: string, value: SyncData}) {
			switch (value.dataType) {
				case 'skillProgress': {
					try {
						const {completedBy, __typename, ...variablesWithoutCompletedBy} = value.rawData;
						const modifiedVariables = {
							...variablesWithoutCompletedBy,
							completedBy: completedBy?.userID || null,
							attempts: variablesWithoutCompletedBy.attempts.map((a: any) => {
								const {__typename, ...rest} = a;
								return {
									...rest,
									feedbackItems: rest.feedbackItems.map((f: FeedbackItem) => {
										const {__typename, ...restOfFeedbackItem} = f;
										return {
											...restOfFeedbackItem
										}
									}),
									instructor: rest.instructor.userID
								};
							})
						}
						await apolloClient.mutate({
							mutation: UPDATE_PROGRESS_MUTATION,
							variables: {
								skillProgress: {
									...modifiedVariables
								}

							}
						});

						dispatch('removeSyncData', key);
					} catch (error) {
						console.error(error);
					}
					// make network call
					break;
				}
				case 'updateAppointments': {
					try {
						await apolloClient.mutate({
							mutation: UPDATE_APPOINTMENTS_MUTATION,
							variables: {
								...value.rawData
							}
						});
						dispatch('removeSyncData', key);
					} catch (error) {
						console.error(error);
					}
					break;
				}
				case 'switchUserToDrivingSession': {
					try {
						await apolloClient.mutate({
							mutation: SWITCH_USER_TO_DRIVING_SESSION_MUTATION,
							variables: {
								...value.rawData
							}
						});
						dispatch('removeSyncData', key);
					} catch (error) {
						console.error(error);
					}
					break;
				}
				case 'vehicleHistory': {
					try {
						await apolloClient.mutate({
							mutation: UPDATE_VEHICLE_HISTORY_MUTATION,
							variables: {
								...value.rawData
							}
						});
						dispatch('removeSyncData', key);
					} catch (error) {
						console.error(error);
					}
					break;
				}
				case 'addUserToSession': {
					try {
						await apolloClient.mutate({
							mutation: ADD_USER_TO_SESSION_MUTATION,
							variables: {
								...value.rawData
							}
						});
						dispatch('removeSyncData', key);
					} catch (error) {
						console.error(error);
					}
					break;
				}
			}
		},
		async runNetworkRequests({commit, dispatch}) {
			try {
				const syncData = await Preferences.get({ key: 'syncData' });
				if (syncData?.value) {
					const syncDataMap: Record<string, SyncData> = JSON.parse(syncData.value);

					const syncDataArray = Object.entries(syncDataMap).sort((a, b) => {
						return a[1].orderTimestamp - b[1].orderTimestamp;
					});
					for (const [key, value] of syncDataArray) {
						await dispatch('makeNetworkCall', { key, value });
					}
				}
			} catch (error) {
				// TODO: add error handling here
				console.error(error);
			}
		},
		async setupSyncData({commit, dispatch, state}) {
			const syncDataString = await Preferences.get({key: 'syncData'});
			if (!state.syncData) {
				state.syncData = new Map();
			}
			if (!syncDataString?.value) {
				return state.syncData;
			}
			// merge with local data
			const syncData = JSON.parse(syncDataString.value);
			for (const [key, value ] of Object.entries(syncData)) {
				setStoreSyncDataByType(key, value, state.syncData)
			}

			await this.dispatch('runNetworkRequests');
		},
		addSyncData({commit}, syncData: SyncData) {
			commit('addSyncData', syncData);
		},
		removeSyncData({commit}, syncDataKey: string) {
			commit('removeSyncData', syncDataKey);
		},
		updateSyncData({commit}, syncData: SyncData) {
			commit('updateSyncData', syncData);
		},
		clearSyncData({commit}) {
			commit('clearSyncData');
		}
	},
	mutations: {
		async addSyncData(state, syncData: SyncData) {
			if (!state.syncData) {
				state.syncData = new Map();
			}
			const newSyncId = uuidv4();
			if (!syncData.rawData.id) {
				// create new id
				// check the data type and add the id to the higher-level array
				syncData.rawData.id = uuidv4();
			}
			syncData.localId = syncData.rawData.id;
			state.syncData.set(newSyncId, syncData);

			Preferences.set({
				key: 'syncData',
				value: JSON.stringify(Object.fromEntries(state.syncData))
			});
		},
		removeSyncData(state, syncDataKey: string) {
			if (!state.syncData) {
				return;
			}
			state.syncData.delete(syncDataKey);
			Preferences.set({
				key: 'syncData',
				value: JSON.stringify(Object.fromEntries(state.syncData))
			});


		},
		updateSyncData(state, syncData: SyncData) {
			if (!state.syncData) {
				return;
			}
			state.syncData[syncData.id] = syncData;
		},
		clearSyncData(state) {
			state.syncData = null;

			Preferences.remove({ key: 'syncData' });
		},
	}
}