<template>
	<ion-item
		:ripple="false"
		color="light"
		:detail="false"
		:class="['ion-margin-bottom', 'ion-no-padding', 'time-slot-card']"
		lines="none"
	>
		<div class="time-slot-card">
			<div :class="['time-slot-card__header', store.getters.institutionSettings?.schedulerSettings?.hideInstructors ? 'time-slot-card__header-padding--no-instructor' : 'time-slot-card__header-padding--instructor', timeSlot.isScheduled ? 'time-slot-card__header--reseved-color' : 'time-slot-card__header--base-color']">
				<p v-if="!store.getters.institutionSettings?.schedulerSettings?.hideInstructors">
					{{ timeSlot.instructor.firstName }} {{ timeSlot.instructor.lastName }}
				</p>
			</div>
			<div class="session-time-container">
				<div
					:key="index"
					v-for="(sessionGroup, index) in uniqueSessionTimes.values()"
					class="session-time"
				>
					<div class="session-time__details">
						<p>
							<b v-if="store.getters.institutionSettings.schedulerSettings?.timeSlotTitle === 'LABEL' && timeSlot.label">
								{{ timeSlot.label }}
							</b>
							<b v-else-if="store.getters.institutionSettings.schedulerSettings?.timeSlotTitle === 'TIME_LABEL' && timeSlot.label">
								{{ `${timeSlot.label} (${formatTime(sessionGroup[0].sessionStart)} - ${formatTime(sessionGroup[0].sessionEnd)})` }}
							</b>
							<b v-else>
								{{ formatTime(sessionGroup[0].sessionStart) }} - {{ formatTime(sessionGroup[0].sessionEnd) }}
							</b>
						</p>
						<p v-if="sessionGroup[0].customPickupLocation?.name || (timeSlot.location?.name && timeSlot.location?.locationID !== 'none')">
							{{sessionGroup[0].customPickupLocation?.name || timeSlot.location?.name }}
						</p>
					</div>
					<div class="session-time__reserve-buttons">
						<ion-button
							:class="['reserve-button', getButtonFillColorAndIconColor(sessionGroup, 'drive').fill, getButtonFillColorAndIconColor(sessionGroup, 'drive').borderColor]"
							:color="getButtonFillColorAndIconColor(sessionGroup, 'drive').buttonColor"
							@click="toggleSelectedSession('drive', sessionGroup[0].sessionStart, sessionGroup)"
							:disabled="disabledReserveButton(sessionGroup, 'drive')"
							>
							<slot name="icon-only">
								<ion-icon
									:color="getButtonFillColorAndIconColor(sessionGroup, 'drive').iconColor"
									src="/assets/icon-gauge-low.svg"
								/>
							</slot>
						</ion-button>
						<ion-button
							v-if="!store.getters.observeDisabled"
							:color="getButtonFillColorAndIconColor(sessionGroup, 'observe').buttonColor"
							:class="['reserve-button', getButtonFillColorAndIconColor(sessionGroup, 'observe').fill, getButtonFillColorAndIconColor(sessionGroup, 'observe').borderColor]"
							@click="toggleSelectedSession('observe', sessionGroup[0].sessionStart, sessionGroup)"
							:disabled="disabledReserveButton(sessionGroup, 'observe')"
						>
							<slot name="icon-only">
								<ion-icon
									:color="getButtonFillColorAndIconColor(sessionGroup, 'observe').iconColor"
									src="/assets/icon-eye.svg"
								/>
							</slot>
						</ion-button>
					</div>
				</div>
				<div class="session-time-container__cancel-button">
					<ion-button
						expand="block"
						v-if="timeSlot.isScheduled && !timeSlot.isPast"
						@click="cancelReservation"
					>
						CANCEL
					</ion-button>
				</div>
			</div>
		</div>

	</ion-item>

</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import {
	eye,
	speedometer,
} from "ionicons/icons";
import { useStore } from "vuex";
import { IonIcon, IonItem } from "@ionic/vue";
import { useRouter } from 'vue-router';
import { toast } from '@/core/toast/Toast';
import { formatTime } from "@/core/util/helpers";
import { SessionDetails, TimeSlotVM } from "@/core/store/store";

export default defineComponent({
	name: "TimeSlotGranularAdjustmentItem",
	components: {
		IonIcon,
		IonItem,
	},
	props: {
		futureAppointmentsLocked: {
			type: Object,
			default: false,
		},
		timeSlot: {
			type: Object as PropType<TimeSlotVM>,
			default: () => {}
		},
		timeSlotIDOfSelectedReservations: {
			type: String,
			default: '',
		},
		inWaitingPeriod: {
			type: Boolean,
			default: false,
		},
		timeSlotsOnSelectedDate: {
			type:  Array as PropType<TimeSlotVM[]>,
			default: () => [],
		},
	},
	setup() {
		const router = useRouter();
		const store = useStore();

		return {
			eye,
			router,
			speedometer,
			store,
		};
	},
	data() {
		return {
			selectedSessions: null,
			// hard-coded reservation limit for now
			reservationLimit: 2,
		}
	},
	computed: {
		selectedUser() {
			return this.store.getters.selectedUser ?? this.store.getters.user.userID;
		},
		scheduledTimeSlotOnDate() {
			return this.timeSlotsOnSelectedDate.some(ts => ts.isScheduled);
		},
		uniqueSessionTimes() {
			const sessionTimeMap = new Map<string, SessionDetails[]>();

			this.timeSlot.instance.sessions.forEach((session) => {
				if (!sessionTimeMap.has(session.sessionStart)) {
					sessionTimeMap.set(session.sessionStart, [])
				}

				sessionTimeMap.get(session.sessionStart).push(session);
			})

			return sessionTimeMap;
		},
		disableSessionGroupBasedOnLockSettings() {
			return ([...this.uniqueSessionTimes.values()].some(sessionGroup => {
				return sessionGroup.find(session => !session.userID &&
					this.futureAppointmentsLocked[session.sessionType === 'observe' ? 'lockedObserves' : 'lockedDrives']
				)
			}));
		},
		reservationTypeSetting() {
			// default to 'ADJACENT'
			return this.store.getters.institutionSettings?.schedulerSettings?.sessionSelection?.sessionReservationType || 'ADJACENT';
		},
		isSingleHourTimeSlot() {
			return this.uniqueSessionTimes.size === 1;
		},
		driveIsSelected() {
			if (!this.selectedSessions) return false;
			return Object.values(this.selectedSessions).some(({sessionType}) => sessionType === 'drive');
		},
		reservationLimitReached() {
			if (this.reservationTypeSetting === 'ALL') return false;
			if (!this.selectedSessions) return false;
			if (Object.keys(this.selectedSessions).length >= this.reservationLimit) return true;
			return false;
		},
		reservationConfirmEnabled() {
			if (!this.selectedSessions) return false;
			if (this.reservationLimitReached) return true;
			if (!this.reservationLimitReached && this.noAdjacentSessionsToSelectedSessionAvailable) return true;
			return false;
		},
		noAdjacentSessionsToSelectedSessionAvailable() {
			if (!this.selectedSessions) return false;
			if (!this.timeSlotHasADriveAvailable) return true;
			return [...this.uniqueSessionTimes.entries()].every(([sessionStart, sessionGroup]) => {
				if (this.selectedSessions[sessionStart]) return true;
				if (this.noSessionAvailable(sessionGroup, 'observe')) return true;
			});
		},
		timeSlotHasADriveAvailable() {
			return [...this.uniqueSessionTimes.values()].some((sessionGroup) => sessionGroup.some(({sessionType, userID}) => !userID && sessionType === 'drive'));
		},
	},
	methods: {
		formatTime,
		getReservationMessage() {
			if (this.reservationTypeSetting === 'ADJACENT') {
				if (!this.reservationLimitReached &&
					!this.isSingleHourTimeSlot &&
					!this.noAdjacentSessionsToSelectedSessionAvailable
				) {
					return 'Choose 1 more observation to complete reservation.';
				}
				return '';
			}
		},
		getButtonFillColorAndIconColor(sessionGroup, sessionType) {
			const buttonColor = this.getButtonColor(sessionGroup, sessionType);
			return {
				buttonColor: this.getButtonColor(sessionGroup, sessionType),
				fill: this.getFillStyle(buttonColor),
				iconColor: this.getIconColor(buttonColor),
				borderColor: this.getButtonBorderColor(buttonColor),
			}
		},
		getFillStyle(buttonColor) {
			switch(buttonColor) {
				case 'success':
				case 'medium':
					return 'solid-fill';
				case 'white':
				default:
					return 'outline-fill';
			}
		},
		getIconColor(buttonColor) {
			switch(buttonColor) {
				case 'success':
					return 'light';
				case 'medium':
					return 'light';
				case 'white':
					return 'primary';
				default:
					return 'dark';
			}
		},
		getButtonBorderColor(buttonColor) {
			switch(buttonColor) {
				case 'success':
					return 'success-border';
				case 'medium':
					return 'light-border';
				case 'white':
					return 'primary-border';
				default:
					return 'primary-border';
			}
		},
		getButtonColor(sessionGroup, sessionType) {
			if (this.selectedSessions?.[sessionGroup[0].sessionStart]?.sessionType === sessionType) return 'success';
			if (this.selectedUserIsScheduledForSessionType(sessionGroup, sessionType)) return 'success';
			if (this.noSessionAvailable(sessionGroup, sessionType)) return 'medium';
			if (this.selectedUserScheduledForTimeSlotButNotGroup(sessionGroup)) return 'white';
			if (this.selectedUserIsScheduledDuringSessionTime(sessionGroup, sessionType)) return 'white';
			return 'white';
		},
		disabledReserveButton(sessionGroup, driveOrObserve) {
			if (this.scheduledTimeSlotOnDate) return true;
			if (this.isSelected(sessionGroup, driveOrObserve)) return false;
			if (this.driveIsSelected && driveOrObserve === 'drive') return true;
			if (!this.isAdjacentToSelectedSessions(sessionGroup)) return true;

			if (this.store.getters.institutionSettings?.schedulerSettings?.sessionSelection?.frontLoadRegistration) {
				const firstOpenDrive = [...this.uniqueSessionTimes.values()].find((sessionGroup) => {
					return !!sessionGroup.find(({sessionType, userID}) => sessionType === 'drive' && !userID)
				});
				if (firstOpenDrive) {
					if (firstOpenDrive[0]?.sessionStart === sessionGroup[0].sessionStart) {
						if (driveOrObserve === 'observe') {
							return true;
						}
					} else {
						if (driveOrObserve === 'drive') {
							return true;
						} else if (!this.isAdjacentToSelectableDrives(sessionGroup)) {
							return true;
						}
					}
				}
			}

			// disables driveOrObserve button if user is scheduled for the other session type
			if (this.selectedSessions?.[sessionGroup[0].sessionStart] && this.selectedSessions[sessionGroup[0].sessionStart].sessionType !== driveOrObserve) return true;

			if (this.disableSessionGroupBasedOnLockSettings) return true;
			if (this.reservationLimitReached) return true;
			if (this.inWaitingPeriod) return true;
			if ((this.futureAppointmentsLocked.lockedDrives && sessionGroup.find(session => session.sessionType === 'drive' && !session.userID))
				|| (this.futureAppointmentsLocked.lockedObserves && sessionGroup.find(session => session.sessionType === 'observe' && !session.userID))) return true;
			if (this.futureAppointmentsLocked.locked) return true;
			if (this.timeSlot.isPast) return true;
			if (this.noSessionAvailable(sessionGroup, driveOrObserve)) return true;
		},
		toggleSelectedSession(sessionType, sessionStart, sessionGroup) {
			try {
				if ((!this.driveIsSelected && sessionType === 'observe' && !this.isSingleHourTimeSlot && this.timeSlotHasADriveAvailable)
				) {
					toast.error({
						message: 'Please select a drive first.',
						duration: 3000,
						position: 'bottom'
					});
					return;
				}
				if (this.selectedSessions?.[sessionStart]?.sessionType === sessionType) {
					delete this.selectedSessions[sessionStart];
					if (sessionType !== 'drive') {
						this.removeNonAdjacentReservations(sessionStart);
					}
					this.removeAllWhenNoDriveSelected();
				} else {
					this.selectedSessions = {
						...this.selectedSessions,
						[sessionStart]: {sessionType, sessionEnd: sessionGroup[0].sessionEnd},
					};
					if (sessionType === 'drive') {
						this.autoFillSelections();
					}
				}

				// remove if empty
				if (this.selectedSessions && Object.keys(this.selectedSessions).length === 0) {
					this.selectedSessions = null;
				}

				this.$emit('updateReservationState', this.selectedSessions, this.timeSlot.timeSlotID, this.getReservationMessage(), this.reservationConfirmEnabled);
			} catch(e) {
				console.error(e);
			}
		},
		autoFillSelections() {
			if (this.reservationTypeSetting === 'ALL') {
				this.selectedSessions = {
					...this.selectedSessions,
					...[...this.uniqueSessionTimes.entries()].reduce((agg, curr) => {
						const [sessionStart, oSessionGroup] = curr;
						if (this.selectedSessions[sessionStart]) {
							return agg;
						}
						if(oSessionGroup.filter(sesh => !sesh.userID && sesh.sessionType !== 'drive')) {
							agg[sessionStart] = { sessionType: 'observe', sessionEnd: oSessionGroup[0].sessionEnd };
						}
					return agg;
					}, {})
				};
			}
			if (this.reservationTypeSetting === 'ADJACENT' && !this.reservationLimitReached) {
				// get adjacent sessions
				const currentSelectedSessionStart = Object.keys(this.selectedSessions)[0];
				const indexOfSelectedSession = [...this.uniqueSessionTimes.keys()].findIndex((sessionStart) => sessionStart === currentSelectedSessionStart);
				const filteredSessionTimes = [...this.uniqueSessionTimes.entries()].filter(([_, uSessionGroup], index) => {
					if (index === indexOfSelectedSession)
						return false;

					const hasOpenObserve = uSessionGroup.some(sesh => !sesh.userID && sesh.sessionType === 'observe');
					if (!hasOpenObserve) return false;

					return index === indexOfSelectedSession-1 || index === indexOfSelectedSession+1;
				});
				if (filteredSessionTimes.length === 1) {
					this.selectedSessions = {
						...this.selectedSessions,
						// filteredSessionTimes[0] is the first unique session time + group
						// filteredSessionTimes[0][0] is the start time
						// filteredSessionTimes[0][1] is the group
						// filteredSessionTimes[0][1][0] is the first session of the group, used to assume the sessionEnd of the whole grouping
						[filteredSessionTimes[0][0]]: { sessionType: 'observe', sessionEnd: filteredSessionTimes[0][1][0].sessionEnd},
					};
				}
			}
		},
		removeAllWhenNoDriveSelected() {
			if (!this.driveIsSelected && !this.isSingleHourTimeSlot) {
				this.selectedSessions = null;
			}
			if (this.isSingleHourTimeSlot && !this.driveIsSelected && this.timeSlotHasADriveAvailable) {
				this.selectedSessions = null;
			}
		},
		removeNonAdjacentReservations(removedSessionStart) {
			const allSessionStarts = [...this.uniqueSessionTimes.keys()];
			const selectedSessions = Object.entries(this.selectedSessions).sort((a, b) => {
				return a[0].localeCompare(b[0])
			});

			const driveSession = selectedSessions.find(([_, {sessionType}]) => sessionType === 'drive');
			if (!driveSession) return;

			const indexOfDriveSession = allSessionStarts.findIndex((time) => time === driveSession[0]);
			const indexOfRemovedSession = allSessionStarts.findIndex((time) => time === removedSessionStart);

			if (indexOfDriveSession > indexOfRemovedSession) {
				// Remove everything *before* the removed session
				allSessionStarts.forEach((sessionStart) => {
					if (sessionStart < removedSessionStart) {
						delete this.selectedSessions[sessionStart];
					}
				});
			} else {
				// Remove everything *after* the removed session
				allSessionStarts.forEach((sessionStart) => {
					if (sessionStart > removedSessionStart) {
						delete this.selectedSessions[sessionStart];
					}
				});
			}
		},
		isSelected(sessionGroup, sessionType) {
			return this.selectedSessions?.[sessionGroup[0].sessionStart]?.sessionType === sessionType;
		},
		noSessionAvailable(sessionGroup, sessionType) {
			return sessionGroup.filter(sesh => sesh.sessionType === sessionType).every(sesh => sesh.userID && sesh.userID !== this.selectedUser);
		},
		selectedUserIsScheduledForSessionType(sessionGroup, sessionType) {
			return sessionGroup.some(sesh => sesh.userID === this.selectedUser && sesh.sessionType === sessionType);
		},
		selectedUserIsScheduledDuringSessionTime(sessionGroup, sessionType) {
			return sessionGroup.some(sesh => sesh.userID === this.selectedUser && sessionType !== sesh.sessionType);
		},
		selectedUserScheduledForTimeSlotButNotGroup(sessionGroup) {
			if (sessionGroup.every(sesh => sesh.userID !== this.selectedUser && this.timeSlot.isScheduled)) return true;
		},
		isAdjacentToSelectedSessions(sessionGroup) {
			if (!this.selectedSessions) return true;
			const allSessionStarts = [...this.uniqueSessionTimes.keys()];
			const selectedSessionStarts = Object.keys(this.selectedSessions || {})
			const startTimeToCheck = sessionGroup[0].sessionStart;
			const idx = allSessionStarts.indexOf(startTimeToCheck);
			if (idx === -1) throw new Error("Invalid session group");
			if (allSessionStarts[idx-1] && selectedSessionStarts.includes(allSessionStarts[idx-1])) return true;
			if (allSessionStarts[idx+1] && selectedSessionStarts.includes(allSessionStarts[idx+1])) return true;
			return false;
		},
		isAdjacentToSelectableDrives(sessionGroup) {
			const allSessionStarts = [...this.uniqueSessionTimes.keys()];
			const allDriveStartTimes = [...this.uniqueSessionTimes.entries()].filter(([_, group]) => group.some(({userID, sessionType}) => !userID && sessionType === 'drive')).map(([sessionStart, _]) => sessionStart);
			if (this.store.getters.institutionSettings?.schedulerSettings?.sessionSelection?.frontLoadRegistration) {
				allDriveStartTimes.splice(1); // Only keep first drive
			}
			const startTimeToCheck = sessionGroup[0].sessionStart;
			const idx = allSessionStarts.indexOf(startTimeToCheck);
			if (idx === -1) throw new Error("Invalid session group");
			if (allSessionStarts[idx-1] && allDriveStartTimes.includes(allSessionStarts[idx-1])) return true;
			if (allSessionStarts[idx+1] && allDriveStartTimes.includes(allSessionStarts[idx+1])) return true;
			return false;
		},
		cancelReservation() {
			this.$emit('cancelReservation', this.timeSlot);
		},
	},
	watch: {
		timeSlotIDOfSelectedReservations(val) {
			if (val !== this.timeSlot.timeSlotID) this.selectedSessions = null;
		},
	},
})
</script>

<style lang="scss" scoped>
ion-item {
	--inner-padding-end: 0;
}

.time-slot-card {
	border-radius: var(--ion-border-radius, 6px);
	background: var(--surface, #FFF);
	box-shadow: var(--box-shadow, 0px 1px 1px 0px rgba(0, 0, 0, 0.25));
	width: 100%;
	display: flex;
	flex-direction: column;
	font-size: 12px;

	&__header {
		color: var(--ion-color-light, #FFF);
		font-size: 14px;
		line-height: normal;
		font-weight: 700;

		&--reseved-color {
			background: var(--ion-color-success);
		}
		&--base-color {
			background: var(--ion-color-primary-lighten-3, #6B7985);
		}
	}



	&__header-padding--instructor {
		padding: 10px;
	}

	&__header-padding--no-instructor {
		padding: 3px;
	}
}

.session-time-container {
	padding: 15px;

	&__cancel-button {
		width: 100%;
		display: flex;
		justify-content: center;
		padding-top: 10px;

		ion-button {
			width: 100%;
			font-size: 14px;
			font-weight: 500;
			line-height: 257%;
			letter-spacing: 0.84px;
			--border-radius: 6px;
			--background: var(--ion-color-danger);
			--box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.25);
		}
	}
}

.session-time {
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;

	&__details {
		display: flex;
		flex-direction: column;
		gap: 5px;
	}

	&__reserve-buttons {
		display: flex;
		align-items: flex-start;
		justify-content: space-between;
		gap: 15px;

		ion-button {
			--border-radius: 99999px !important;
		}
	}
}

.reserve-button {
	width: 50px;
	height: 50px;

	--padding-start: 5px;
	--padding-end: 5px;

	--border-radius: 100%;
	--border-style: solid;
	--border-color: var(--ion-color-primary-lighten-3, #6B7985);
	--border-width: 1px;
	--box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.25);

	ion-icon {
		font-size: 100px;
	}
}

.solid-fill {
	--background: var(--ion-color-dark);
}
.outline-fill {
	--background: var(--ion-color-light, #FFF);
}

.success-border {
	opacity: 1;
	--border-color: var(--ion-color-success);
}
.light-border {
	--border-color: var(--ion-color-light);
}
.primary-border {
	--border-color: var(--ion-color-primary);
}
</style>