<template>
	<div :class="[
		'session-student-card',
		(!hasDetails) ? 'session-student-card--no-details' : '',
		(sessionState === 'completeSession') ? 'session-student-card--complete-session' : ''
	]">
		<div class="session-student-card__header">
			<div class="session-student-card__student-bar">
				<button
					class="session-student-card__button"
					@click="swapStudent"
					v-if="session && session.sessionType === 'observe' && session.status === 'SCHEDULED' && sessionState !== 'completeSession'"
				>
					<MaterialDesignIcon icon="swapHorizontal" color="white" />
				</button>
				<div class="driving-card__student-details-info">
					<h3>
						<span>{{ session?.studentInstructorAppData?.user?.lastName || '' }}</span>
					</h3>
					<p>
						{{ session?.studentInstructorAppData?.user?.firstName || '' }}
					</p>
				</div>
				<div class="driving-card__counts">
					<CountTally
						:current="
							sessionStatus === 'COMPLETED'
							&& session?.sessionType === 'drive'
							&& session.status !== 'COMPLETED'
							&& sessionState === 'completeSession'
								? (session?.studentInstructorAppData?.studentCourse?.driveHoursCompleted || 0) + 1
								: session?.studentInstructorAppData?.studentCourse?.driveHoursCompleted || 0
							"
						mdiIcon="gauge"
						:previous="session?.studentInstructorAppData?.studentCourse?.driveHoursCompleted || 0"
					/>
					<CountTally
						:current="
							sessionStatus === 'COMPLETED'
							&& session?.sessionType === 'observe'
							&& session.status !== 'COMPLETED'
							&& sessionState === 'completeSession'
								? (session?.studentInstructorAppData?.studentCourse?.observationHoursCompleted || 0) + 1
								: session?.studentInstructorAppData?.studentCourse?.observationHoursCompleted || 0
							"
						mdiIcon="eye"
						:previous="session?.studentInstructorAppData?.studentCourse?.observationHoursCompleted || 0 "
					/>
				</div>
				<contact-user-modal :user="session?.studentInstructorAppData?.user" />
			</div>
			<SessionStatusButton
				@update="updateSessionStatus"
				:sessionStatus="sessionStatus"
				v-if="sessionState === 'completeSession'"
			/>
		</div>
		<div class="session-student-card__details">
			<div class="driving-card__balance-due" v-if="session?.studentInstructorAppData?.balanceDue">
				<MaterialDesignIcon icon="cashClock" color="white" /><span>Balance Due - {{
					session?.studentInstructorAppData?.balanceDue > 0
						? `(${formatDollarAmount(session?.studentInstructorAppData?.balanceDue)})`
						: `${formatDollarAmount(Math.abs(session?.studentInstructorAppData?.balanceDue) || 0)}`
				}}</span>
			</div>
			<div
				class="driving-card__require-corrective-lens"
				v-if="session?.studentInstructorAppData?.user?.correctiveLensesRequired"
			>
				<MaterialDesignIcon icon="glasses" color="white" />
				<span>Require Corrective Lenses</span>
			</div>
			<div
				:class="['driving-card__student-notes', `driving-card__note-color-${note.type}`]"
				v-bind:key="index"
				v-for="(note, index) in session?.studentInstructorAppData?.user?.notes?.adminScheduler || []"
			>
				<p class="driving-card__notebook-edit">
					<MaterialDesignIcon icon="notebookEdit" /><span v-html="note.note"></span>
				</p>
			</div>
			<div class="driving-card__skills" v-if="session?.sessionType === 'drive' && sessionState !== 'completeSession'">
				<div class="driving-card__skills-section">
					<div class="driving-card__section-header">
						<span>Last Behind The Wheel</span>
						<button class="driving-card__section-header-button" @click="$emit('openSessionSkillModal', 'sessions')">
							<MaterialDesignIcon icon="calendarMonth" color="white" />
						</button>
					</div>
					<div
						class="driving-card__last-btw-info"
						v-if="lastBehindTheWheel"
					>
						<p>
							<MaterialDesignIcon icon="calendar" color="black" />
							<span>{{ lastBehindTheWheel?.date && format(parseISO(lastBehindTheWheel?.date), "LLL dd, yyy") }}</span>
						</p>
						<p>
							<MaterialDesignIcon icon="account" color="black" />
							<span>
								{{ lastBehindTheWheel?.instructor?.firstName }}
								{{ lastBehindTheWheel?.instructor?.lastName }}
							</span>
						</p>
					</div>
					<div class="driving-card__last-btw-info" v-else>
						<p>N/A</p>
					</div>
				</div>
				<div class="driving-card__skills-section">
					<div class="driving-card__section-header">
						<span>Past Session Notes</span>
					</div>
					<ul class="driving-card__notes-list">
						<SessionNotesRow
							:session="session"
							v-bind:key="index"
							v-for="(session, index) in notesRows || []"
							v-if="notesRows?.length"
						/>
						<li class="driving-card__no-data" v-else>
							<ion-icon class="no-data-icon" color="primary" :icon="informationCircle" size="small"></ion-icon>
							<p>No Data</p>
						</li>
					</ul>
				</div>
			</div>
			<div class="driving-card__skills" v-if="sessionState === 'completeSession'">
				<div class="driving-card__skills-section">
					<div class="driving-card__section-header">
						<span>Next Appointment</span>
					</div>
					<SessionCard
						v-if="nextAppointment?.date"
						:date="nextAppointment.date"
						:oInstructor="nextAppointment?.instructor"
						:light="true"
						:loading="loading"
						:oLocation="(nextAppointment?.sessionDetails?.customPickupLocation?.name) ? nextAppointment?.sessionDetails?.customPickupLocation : nextAppointment?.location"
						:sessionEnd="nextAppointment?.sessionDetails?.sessionEnd"
						:sessionStart="nextAppointment?.sessionDetails?.sessionStart"
						:oVehicle="nextAppointment?.vehicle"
					/>
					<div v-else class="driving-card__no-data">
						<ion-icon class="no-data-icon" color="primary" :icon="informationCircle" size="small"></ion-icon>
						<p>No Appointments Scheduled</p>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { defineComponent } from "vue";
import { location, time, speedometer, eye, call, mail, informationCircle} from "ionicons/icons";
import { useStore } from "vuex";
import { IonIcon, IonItem, IonLoading, IonSkeletonText, menuController } from "@ionic/vue";
import { useRouter } from "vue-router";
import MaterialDesignIcon from "@/view/components/MaterialDesignIcon.vue";
import ProgressCircle from '@/view/components/ProgressCircle.vue';
import SessionStatusButton from "@/view/components/buttons/SessionStatusButton.vue";
import { format, parseISO } from "date-fns";
import { readFragment } from "@/core/api/drivingApi";
import CountTally from "@/view/components/content/CountTally.vue";
import SessionCard from "@/view/components/cards/SessionCard.vue";
import SessionNotesRow from "./SessionNotesRow.vue";
import ContactUserModal from "../modals/ContactUserModal.vue";
import { deepCopy } from '@/core/util/helpers';

export default defineComponent({
	name: "SessionStudentCard",
	emits: ["studentSwap","openSessionSkillModal","statusUpdate"],
	components: {
		CountTally,
		IonIcon,
		IonItem,
		IonLoading,
		IonSkeletonText,
		MaterialDesignIcon,
		ProgressCircle,
		SessionCard,
		SessionStatusButton,
		SessionNotesRow,
		ContactUserModal
	},
	data() {
		return {
			sessionStatus: this.session.userID
				? this.session.status !== 'SCHEDULED'
					? this.session.status
					: 'COMPLETED'
				: null,
			student: {},
		};
	},
	methods: {
		deepCopy,
		format,
		parseISO,
		formatDollarAmount(amount) {
			return new Intl.NumberFormat("en-US", {
				style: "currency",
				currency: "USD",
			}).format(amount);
		},
		getPercentage(amount, total) {
			if(!amount || !total) { return 0; }

			let percentage = amount / total;

			return +percentage.toFixed(2) * 100;
		},
		readFragment,
		selectStudent() {
			this.store.commit("setSelectedSessionStudent", this.student.user);
			this.closeMenu("student-menu");
		},
		swapStudent() {
			this.$emit("studentSwap", this.session);
		},
		updateSessionStatus(newStatus) {
			this.sessionStatus = newStatus || this.sessionStatus;
			this.$emit('statusUpdate', {
				sessionID: this.sessionID,
				sessionStatus: newStatus
			});
		}
	},
	props: {
		loading: {
			default: false,
			type: Boolean
		},
		session: {
			type: Object
		},
		sessionID: {
			type: String,
			required: true,
		},
		studentInstructorAppData: {
			type: Object
		},
		sessionState: {
			default: '',
			type: String
		},
		totalSkills: {
			default: 0,
			type: Number
		},
		userID: {
			type: String,
			required: true,
		},
	},
	setup() {
		const router = useRouter();
		const store = useStore();

		const closeMenu = async (menu) => {
			await menuController.close(menu);
		};

		return {
			closeMenu,
			location,
			speedometer,
			eye,
			router,
			store,
			time,
			call,
			mail,
			informationCircle,
		};
	},
	computed: {
		averageSkillRating() {
			if (!this.studentInstructorAppData) { return 0; }

			let ratingSum = 0;
			let ratingCount = 0;

			ratingSum = this.studentInstructorAppData.studentSkillProgress.reduce((agg, curr) => {
				let scoreTotal = curr.attempts.reduce((agg, curr) => {

					ratingCount++;
					agg += curr.score;

					return agg;
				}, 0);

				return agg += scoreTotal;
			}, ratingSum);

			return (ratingSum && ratingCount) ? (ratingSum / ratingCount).toFixed(2) : 0;
		},
		lastBehindTheWheel() {
			if (!this.studentInstructorAppData?.completedDriveSessions.length) { return null; }

			let completedDriveSessions = this.deepCopy(this.studentInstructorAppData.completedDriveSessions);

			// Sort completed session dates in descending order.
			completedDriveSessions.sort((a, b) => {
				return (new Date(a.date) - new Date(b.date)) * -1;
			});

			return completedDriveSessions[0];
		},
		notesRows() {
			if (!this.studentInstructorAppData?.completedDriveSessions?.some(session => session.sessionDetails?.drivingNotes?.internalNote || session?.sessionDetails?.drivingNotes?.externalNote)) { return []; }

			let sessionNotes = this.studentInstructorAppData?.completedDriveSessions.reduce((agg, curr) => {
				if (!curr.sessionDetails?.drivingNotes?.internalNote && !curr.sessionDetails?.drivingNotes?.externalNote) { return agg; }
				agg.push(curr);
				return agg;
			}, []);

			// Sort note dates in descending order.
			sessionNotes.sort((a, b) => {
				return (new Date(a.date) - new Date(b.date)) * -1;
			});

			return sessionNotes;
		},
		attemptSuccessFailure() {
			let asf = {
				failure: 0,
				failurePercent: 0,
				success: 0,
				successPercent: 0
			}

			if (!this.skillHistoryData) { return asf; }

			this.skillHistoryData.reduce((acc, curr) => {

				if (curr.failures) {
					acc.failure += curr.failures;
				}

				if (curr.successes) {
					acc.success += curr.successes;
				}

				return acc;
			}, asf);

			const totalSuccessFailure = asf.failure + asf.success;

			asf.failurePercent = this.getPercentage(asf.failure, totalSuccessFailure).toFixed(0);
			asf.successPercent = this.getPercentage(asf.success, totalSuccessFailure).toFixed(0);

			return asf;
		},
		completedSkills() {

			if (!this.skillHistoryData) { return 0; }

			return this.skillHistoryData.reduce((acc, curr) => {

				if (curr.completedDate) {
					++acc;
				}

				return acc;
			}, 0);
		},
		hasDetails() {
			if (!this.session) { return false; }
			if (
				this.session?.studentInstructorAppData?.user?.correctiveLensesRequired
				|| this.session?.studentInstructorAppData?.balanceDue
				|| (this.nextAppointment && this.nextAppointment.date && this.session.sessionType !== 'observe')
				|| this.nextAppointment
			) {
				return true;
			} else {
				return false;
			}
		},
		nextAppointment() {
			if (!this.studentInstructorAppData?.scheduledSessions) return null;
			if (!this.studentInstructorAppData && (this.studentInstructorAppData.scheduledSessions.length !== 0)) { return {}; }

			const scheduledSessions = [...this.studentInstructorAppData.scheduledSessions];

			// Make sure the sessions are in date order.
			scheduledSessions.sort((a, b) => {
				return new Date(a.sessionDetails.sessionStart).getTime() - new Date(b.sessionDetails.sessionStart).getTime();
			});

			// Find the index of our current session.
			let currentSessionIndex = scheduledSessions.findIndex((session) => session.sessionDetails.sessionID === this.sessionID);
			let nextFullDaySessionIndex = -1;

			if (currentSessionIndex !== -1) {
				// Find the first next session who's date is the next day.
				nextFullDaySessionIndex = scheduledSessions.findIndex((session) => session.date > scheduledSessions[currentSessionIndex].date);
			}

			// TODO: Replace the if(true) with an institution setting to allow toggling between next full day session away and next session.
			if (true) {
				return (nextFullDaySessionIndex !== -1) ? scheduledSessions[nextFullDaySessionIndex] : {};
			} else {
				return (scheduledSessions[currentSessionIndex + 1]) ? scheduledSessions[currentSessionIndex + 1] : {};
			}
		},
		skillHistoryData() {
			const skillsObjectMap = this.session?.studentInstructorAppData?.studentSkillProgress.reduce((accumulator, currentValue) => {
				if (!accumulator[currentValue.id]) {
					const oSkill = readFragment(`Skill:${currentValue.id}`);
					accumulator[currentValue.id] = {
						completedDate: null,
						skillItem: oSkill.name,
						successes: 0,
						failures: 0,
						failureToSuccessRatio: 0,
					}
				}
				accumulator[currentValue.id].completedDate = currentValue.completedDate;
				accumulator[currentValue.id].successes += currentValue.attempts.filter(a => a.success).length;
				accumulator[currentValue.id].failures += currentValue.attempts.filter(a => !a.success).length;
				accumulator[currentValue.id].failureToSuccessRatio = Math.round(accumulator[currentValue.id].failures / accumulator[currentValue.id].successes, 2);

				return accumulator;
			}, {});

			const skillsArray = Object.values(skillsObjectMap);
			const sortedSkillsArray = skillsArray.sort((a, b) => {
				return b.failureToSuccessRatio - a.failureToSuccessRatio;
			});

			return sortedSkillsArray;
		},
		// TODO: Keep until a decision is made on if we are showing ahead/behind data or not.
		// skillDriveProgress() {
		// 	if (!this.completedSkills || !this.totalSkills || !this.session) { return 0; }
		// 	const skillsProgress = this.completedSkills / this.totalSkills;

		// 	const driveHoursCompleted = this.session?.studentInstructorAppData?.studentCourse?.driveHoursCompleted ?? 0;
		// 	let totalDrives = this.session?.studentInstructorAppData?.studentCourse?.course?.requiredDriveHours ?? 0;
		// 	const behindTheWheelProgress = driveHoursCompleted / totalDrives;
		// 	const aheadBehind = skillsProgress - behindTheWheelProgress;

		// 	return aheadBehind;
		// },
		worst3Skills() {
			if (!this.skillHistoryData) { return []; }
			return this.skillHistoryData.filter((shd) => shd.failures).slice(0, 3).sort((a, b) => {
				return b.failureToSuccessRatio - a.failureToSuccessRatio;
			});
		},
		best3Skills() {
			if (!this.skillHistoryData) { return []; }
			return this.skillHistoryData.filter((shd) => shd.successes).slice(-3).sort((a, b) => {
				return a.failureToSuccessRatio - b.failureToSuccessRatio;
			});
		},
		institutionTZ() {
			return (
				this.store.getters.institutionSettings?.timeZone ?? "America/Chicago"
			);
		},
		buttonColor() {
			if (
				!this.store.getters?.selectedSessionStudent?.userID ||
				!this?.student?.user
			) {
				return "light";
			}
			return this.store.getters.selectedSessionStudent.userID ===
				this.student.user.userID
				? "primary"
				: "light";
		},
		currentStudent() {
			return this.student;
		}
	}
});
</script>

<style lang="scss" scoped>
.driving-card,
.session-student-card {
	border-radius: 6px 6px 0 0;
	box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.25);
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	margin-bottom: 15px;
	overflow: hidden;
	width: 100%;

	&__button {
		align-items: center;
		background-color: var(--ion-color-primary-tint);
		border-radius: 150px;
		display: flex;
		flex-shrink: 0;
		height: 45px;
		justify-content: center;
		width: 45px;

		& .material-design-icon {
			width: 30px;
			height: 30px;
		}
	}

	&__header {
		align-items: stretch;
		display: flex;
		gap: 5px;
		height: 60px;
		overflow: hidden;
	}

		&__student-bar {
			align-items: center;
			background-color: #ffffff;
			display: flex;
			flex: 1;
			flex-direction: row;
			gap: 10px;
			padding: 10px;

			h3, p {
				margin: 0;
				font-size: 16px;
				font-weight: 700;

				.material-design-icon {
					width: 16px;
					height: 16px;
				}
			}
		}

			&__student-details-info {
				display: flex;
				flex-direction: column;
				width: 100%;

				> * {
					display: flex;
					flex-direction: row;
					align-items: flex-start;
					gap: 5px;
				}

				& .material-design-icon {
					margin-top: 2px;
				}

				> p {
					font-size: 12px;
				}
			}

			&__counts {
				align-items: end;
				display: flex;
				flex-direction: column;
			}

	&__details {
		box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.25);
	}

	&__balance-due,
	&__require-corrective-lens {
		align-items: center;
		color: var(--ion-color-light);
		display: flex;
		font-weight: 700;
		padding: 3px 10px;
		width: 100%;

		> span {
			padding-left: 5px;
		}
	}

		&__balance-due {
			background-color: var(--ion-color-warning);
		}

		&__require-corrective-lens {
			background-color: var(--ion-color-danger);
		}

	&__student-notes {
		display: flex;
		padding: 10px;
		flex-direction: column;
		align-items: flex-start;
		gap: 5px;
		align-self: stretch;
	}

	&__notebook-edit {
		display: flex;
		align-items: center;
		gap: 5px;
		justify-content: left;
		text-align: top;
		font-weight: 700;

		& span {
			padding-left: 5px;
		}

		.material-design-icon {
			min-width: 22px;
		}
	}

	&__note-color-WARNING {
		background-color: var(--ion-color-warning-lighten-8);
		border-bottom: 3px solid var(--ion-color-warning);
	}

	&__note-color-ERROR {
		background-color: var(--ion-color-danger-lighten-8);
		border-bottom: 3px solid var(--ion-color-danger);
	}

	&__note-color-INFO {
		background-color: var(--ion-color-tertiary-lighten-8);
		border-bottom: 3px solid var(--ion-color-tertiary);
	}

	&__skills {
		background-color: #FFFFFF;
		width: 100%;
	}

	&__no-data {
		display: flex;
		align-items: center;
		justify-content: start;
		width: 100%;
		height: 100%;
		font-weight: 700;
		padding: 5px 10px;

		.no-data-icon {
			margin-right: 5px;
		}
	}

	&__skills-section {
		display: flex;
		flex-direction: column;
	}

	&__section-header {
		display: flex;
		flex-direction: row;
		color: #ffffff;
		font-weight: 700;
		justify-content: space-between;
		background-color: var(--ion-color-primary);

		span {
			display: flex;
			align-items: center;
			padding: 10px;
		}
	}

	&__section-header-button {
		display: flex;
		justify-content: center;
		width: 44px;
		height: 44px;
		align-items: center;
		background-color: var(--ion-color-primary-tint);
	}

	&__last-btw-info {
		font-weight: 700;
		padding: 10px;
		display: flex;
		flex-direction: column;

		> * {
			align-items: center;
			display: flex;
			flex-direction: row;
			gap: 5px;
		}

		& span {
			padding-left: 5px;
		}
	}

	&__notes-list {
		display: flex;
		flex-direction: column;
		font-weight: 700;
		list-style: none;
		padding: 1px;
		margin: 0;
		scrollbar-color: var(--ion-color-primary) #D9D9D9;
	}

	&--complete-session {

		.session-student-card {
			&__student-bar {
				border-top-right-radius: 6px;
			}
		}
	}

	&--no-details {
		border-radius: 6px;

		&__header {
			overflow: visible;

			ion-button.session-status-button {
				--border-radius: 6px;
			}
		}

		&__student-bar {
			border-radius: 6px;
		}
	}

		&--no-details.session-student-card--complete-session {
			box-shadow: none;
			overflow: visible;

			.session-student-card {

				&__header {
					overflow: visible;
				}

				&__student-bar {
					border-bottom-right-radius: 6px;
					box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.25);
				}
			}

			.session-status-button {
				--border-radius: 6px;
			}
		}
}
</style>

<style>
.material-design-icon--green path {
	fill: var(--ion-color-success) !important;
}

.material-design-icon--red path {
	fill: var(--ion-color-danger) !important;
}
</style>