<template>
	<ion-page>
		<SkillAttemptCRUDModal
			v-if="attemptCRUDModalItem"
			v-on:closeModal="attemptCRUDModal.show = false; attemptCRUDModalItem = null"
			:selectedAttemptID="attemptCRUDModal.selectedAttempt"
			:showDelete="false"
			:skillItem="attemptCRUDModalItem"
			:skillSetID="selectedSkillSetID || currentDriverProgressSkillSetID"
			:show="attemptCRUDModal.show"
			@showSkillDetailsModal="showSkillDetailsModal"
			@showSkillSummaryModal="showSkillSummaryModal"
			@willDismiss="attemptCRUDModal.show = false; attemptCRUDModalItem = null"
			:key="(attemptCRUDModal.selectedAttempt) ? attemptCRUDModal.selectedAttempt+attemptCRUDModalItem.id : attemptCRUDModalItem.id"
		/>

		<SkillDetailsModal
			:show="skillDetailsModal.show"
			:skill="selectedSkill"
			v-on:closeModal="skillDetailsModal.show = false"
		/>

		<SkillSummaryModal
			:show="skillSummaryModal.show"
			:skill="selectedSkill"
			v-on:closeModal="skillSummaryModal.show = false"
			v-on:showDetailsModal="skillDetailsModal.show = true"
			@switchEdit="switchSkillAttemptCRUDModal"
		/>

		<InstructorHeader />

		<ion-content fullscreen scrollY="true" class="session">
			<div class="session__tab-bar">
				<button class="session__tab-bar-tab" @click="backwardsNav">
					<MaterialDesignIcon icon="calendar" color="white" />
				</button>
				<div class="session__details">
					<div class="session__detail-item">
						<MaterialDesignIcon icon="clockTimeFour" color="white" />
						<span v-if="!loading">{{ formatTime(selectedSession?.sessionStart) }} - {{ formatTime(selectedSession?.sessionEnd) }}</span>
						<IonSkeletonText :animated="true" style="height: 16px; margin-left: 15px; width: 180px;" v-else-if="!selectedSession" />
					</div>
					<div class="session__detail-item">
						<MaterialDesignIcon icon="gauge" color="white" />
						<span v-if="!loading">{{ selectedSession?.studentInstructorAppData?.user?.firstName }} {{ selectedSession?.studentInstructorAppData?.user?.lastName }}</span>
						<IonSkeletonText :animated="true" style="height: 16px; margin-left: 15px; width: 180px;" v-else-if="!selectedSession" />
					</div>
				</div>
				<ion-button class="session__tab-bar-tab tab-green" @click="completeSession()">
					<MaterialDesignIcon icon="checkDecagram" color="white" />
				</ion-button>
			</div>

			<div class="skill-set-toolbar">
				<ion-button
					:class="[
						'skill-set-toolbar__select-skillset',
						(showSkillSearch || !hasSkillSets) ? 'skill-set-toolbar__select-skillset--minimize' : ''
					]"
					id="select-skillset"
				>
					<div class="skill-set-toolbar__button-content">
						<span v-if="!loading">{{ selectedSkillset?.name || "" }}</span>
						<IonSkeletonText
							:animated="true"
							style="height: 16px; margin-left: 15px; width: 180px;"
							v-else-if="!selectedSkillset"
						/>
						<IonProgressBar
							buffer="1"
							:class="[(selectedSkillset?.percentCompleted === 1) ? 'complete' : 'in-progress']"
							:value="selectedSkillset?.percentCompleted || 0"
							v-if="!loading && selectedSkillset"
						/>
						<div v-if="!loading && !selectedSkillset">No skill set selected</div>
					</div>
					<MaterialDesignIcon color="black" icon="menuSwap" slot="end" />
				</ion-button>
				<ion-input
					:class="[
						'skill-set-toolbar__search-bar',
						(showSkillSearch || !hasSkillSets) ? 'skill-set-toolbar__search-bar--show' : ''
					]"
					placeholder="Skill Search"
					v-model="skillSearchText"
				>
				</ion-input>
				<ion-button class="skill-set-toolbar__search" @click="toggleSearch">
					<MaterialDesignIcon
						color="white"
						:icon="(skillSearchText ? 'close-circle' : 'search')"
						size="large"
						slot="icon-only"
					/>
				</ion-button>
			</div>

			<IonProgressBar style="--background: var(--ion-color-secondary);" type="indeterminate" v-if="loading" />

			<div class="session__content">

				<SkillCard
					@attemptUpdate="attemptUpdate()"
					:key="keyCreation(skillItem)"
					slot="header"
					:skillItem="skillItem"
					:skillSetID="selectedSkillset && selectedSkillset.id || null"
					:value="index"
					v-for="(skillItem, index) in selectedSkillsetSkills"
					v-if="!loading && selectedSkillsetSkills.length !== 0"
				/>

				<NoticeMessage
					icon="information"
					text="No skills found for skillset."
					v-else-if="!loading"
				/>

				<SkillCardSkeleton v-for="item in 3" v-if="loading && !selectedSkillsetSkills.length" />
			</div>
		</ion-content>


		<ion-modal
			animated="false"
			class="skillset-select-modal"
			:is-open="showSkillsetSelectModal && hasSkillSets"
			ref="skillsetSelectModal"
			trigger="select-skillset"
			@didDismiss="searchText = ''; showSkillsetSelectModal = false;"
		>
			<ion-content class="modal-bottom">
				<ion-searchbar
					class="skillset-select-modal__search"
					@click="$refs.skillsetSelectModal.$el.setCurrentBreakpoint(0.88)"
					placeholder="Search"
					v-model="searchText"
				></ion-searchbar>
				<div class="skillset-select" v-if="filteredSkillSets.length !== 0">
					<SkillsetSelector
						:skillSet="skillSet"
						v-bind:key="skillSet.id"
						:selectedSkillset="(selectedSkillSetID) ? selectedSkillSetID : currentDriverProgressSkillSetID"
						v-for="skillSet in filteredSkillSets"
						@selectSkillset="selectSkillset"
					/>
				</div>
				<NoticeMessage
					icon="information"
					text="No skillsets found."
					v-else-if="!loading"
				/>
			</ion-content>
		</ion-modal>
		<ion-alert
			:is-open="backwardsNavOpenRef"
			:header="backwardsNavAlertMessage"
			subHeader="Are you sure you want to go back?"
			cssClass="backwards-nav instructor-alert"
			:buttons="backwardsNavAlertButtons"
			@didDismiss="setOpen(false, 'backwards')"
		></ion-alert>

	</ion-page>
</template>
<script>
import NoticeMessage from '@/view/components/content/NoticeMessage.vue';
import SkillAttemptCRUDModal from '@/view/components/modals/SkillAttemptCRUDModal.vue';
import InstructorHeader from '../../components/instructor/InstructorHeader.component.vue';
import SkillCard from '@/view/components/cards/SkillCard.vue';
import SkillCardSkeleton from '@/view/components/skeletons/SkillCardSkeleton.vue';
import SkillsetSelector from './SkillsetSelector.vue';
import {
	onIonViewWillEnter,
	IonButton,
	IonPage,
	IonAlert,
	IonContent,
	IonProgressBar,
	IonItem,
	IonList,
	IonAccordionGroup,
	IonAccordion,
	IonSearchbar,
	IonSkeletonText,
} from '@ionic/vue';
import { caretDownCircle } from 'ionicons/icons';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { ref, onMounted, computed } from 'vue';
import { defineComponent } from 'vue';
import {
	GET_INSTITUTION_SKILLS_QUERY,
	GET_INSTITUTION_SKILL_SETS_QUERY,
	GET_INSTRUCTOR_TIME_SLOTS_QUERY,
	UPDATE_PROGRESS_MUTATION,
} from '@/core/api/drivingApi';
import { useLazyQuery, useMutation, useQuery } from '@vue/apollo-composable';
import MaterialDesignIcon from '@/view/components/MaterialDesignIcon.vue';
import { format } from 'date-fns';
import { formatTime } from "@/core/util/helpers";
import { Preferences } from "@capacitor/preferences";
import SkillDetailsModal from '@/view/components/modals/SkillDetailsModal.vue';
import SkillSummaryModal from '@/view/components/modals/SkillSummaryModal.vue';

export default defineComponent({
	components: {
		IonAccordionGroup,
		IonAccordion,
		IonPage,
		IonAlert,
		IonContent,
		IonProgressBar,
		IonItem,
		IonList,
		IonSkeletonText,
		InstructorHeader,
		NoticeMessage,
		SkillCard,
		SkillCardSkeleton,
		SkillsetSelector,
		SkillDetailsModal,
		SkillSummaryModal,
		IonSearchbar,
		MaterialDesignIcon,
		SkillAttemptCRUDModal
	},
	setup() {
		const store = useStore();
		const router = useRouter();
		const storageToCacheSet = ref(false);
		const timeSlotsQueryEnabled = ref(true);

		const timeSlotQueryEnabled = computed(() => {
			return !!store.getters.user?.institutions?.[0]
				&& !!store.getters.activeInstructor?.userID
				&& !!timeSlotsQueryEnabled.value;
		})

		const timeSlotsQuery = useLazyQuery(
			GET_INSTRUCTOR_TIME_SLOTS_QUERY,
			() => ({
				institutionSlug: store.getters.user.institutions[0],
				instructorID: store.getters.activeInstructor?.userID,
				date: store.getters.activeSession.instanceDate
			}),
			{
				deep: true,
				fetchPolicy: 'cache-first',
				enabled: timeSlotQueryEnabled.value
			},
		);
		timeSlotsQuery.onResult(() => {
			if (!storageToCacheSet.value) {
				store.dispatch('applyActiveSessionStorageToCache', {
					sessionID: store.getters.activeSession.sessionID
				});
				storageToCacheSet.value = true;
			}
		});

		onIonViewWillEnter(async () => {
			timeSlotsQueryEnabled.value = true;
			const prefActiveSession = await Preferences.get({ key: 'activeSession' });
			if (prefActiveSession?.value) {
				const activeSession = JSON.parse(prefActiveSession.value);
				store.dispatch('setActiveSession', activeSession);
			}
			timeSlotsQuery.load();
		});

		const timeSlot = computed(() => {
			if (timeSlotsQuery.result.value?.timeSlots) {
				return timeSlotsQuery.result.value?.timeSlots?.find(
					(timeSlot) => timeSlot?.instances?.find((inst) => inst.id === store.getters.activeSession.instanceID)
				);
			} else {
				return store.getters.instructorHomeTimeSlots?.find((timeSlot) => timeSlot?.instances?.find((inst) => inst.id === store.getters.activeSession.instanceID));
			}
		});

		const setOpen = (state) => (backwardsNavOpenRef.value = state);
		const backwardsNavOpenRef = ref(false);

		const institutionSkillSetsQuery = useQuery(
			GET_INSTITUTION_SKILL_SETS_QUERY,
			() => ({
				institutionSlug: store.getters.user.institutions[0],
			}),
			{
				fetchPolicy: (store.getters.connectionStatus.connected) ? 'cache-and-network' : 'cache-only',
				// 5 minutes
				pollInterval: 300000,
				enabled: computed(() => !!store.getters.isAuthenticated)
			}
		);
		const institutionSkillSets = computed(() => {
			if (!institutionSkillSetsQuery.result?.value?.skillSets) { return null; }
			return institutionSkillSetsQuery.result?.value?.skillSets.filter((skillSet) => skillSet.isDeleted !== true && skillSet.skills.length > 0)
		});

		const institutionSkillsQuery = useQuery(
			GET_INSTITUTION_SKILLS_QUERY,
			() => ({
				institutionSlug: store.getters.user.institutions[0],
			}),
			{
				fetchPolicy: (store.getters.connectionStatus.connected) ? 'cache-and-network' : 'cache-only',
				// 5 minutes
				pollInterval: 300000,
				enabled: computed(() => !!store.getters.isAuthenticated)
			}
		);
		const institutionSkills = computed(() => {
			if (!institutionSkillsQuery.result?.value?.skills) { return null; }
			return institutionSkillsQuery.result?.value?.skills.filter((skill) => skill.isDeleted !== true)
		});

		const skillProgressVariables = ref();
		const submitProgressMutation = useMutation(
			UPDATE_PROGRESS_MUTATION
		);

		return {
			store,
			router,
			loading: timeSlotsQuery.loading || institutionSkillSetsQuery.loading || institutionSkillsQuery.loading,
			institutionSkills,
			institutionSkillSets,
			submitProgressMutation,
			skillProgressVariables,
			timeSlot,
			ref,
			onMounted,
			caretDownCircle,
			setOpen,
			backwardsNavOpenRef,
			timeSlotsQuery,
			timeSlotsQueryEnabled
		};
	},
	ionViewDidEnter() {
		this.timeSlotsQueryEnabled = true;
		this.showSkillsetSelectModal = true;
	},
	ionViewWillLeave() {
		this.counter++;
		this.timeSlotsQueryEnabled = false;
	},
	computed: {
		skillSettings() {
			return this.store.getters.skillSettings;
		},
		currentDriverProgressSkillSetID() {
			if (this.skillSetsWithSkillsUserData) {
				const currentDriverProgressSkillSet = this.skillSetsWithSkillsUserData.find((skillSet) => skillSet.skillsCompleted !== skillSet.totalSkills);
				return (currentDriverProgressSkillSet) ? currentDriverProgressSkillSet.id : null;
			}

			return null;
		},
		hasSkillSets() {
			if (!this.institutionSkillSets || !this.skillSettings.showSkillsBySkillSet) { return false; }
			return (this.institutionSkillSets.length > 0);
		},
		skillsWithSkillUserData() {
			let skillsWithData = [];

			if (!this.selectedSession?.studentInstructorAppData?.studentSkillProgress || !this.institutionSkills) { return []; }

			let skillsDone = 0;

			skillsWithData = this.institutionSkills?.map((skill) => {
				const skillProgressItem  =
					this.selectedSession?.studentInstructorAppData?.studentSkillProgress?.find(
						(sp) => sp.id === skill.id
					);

				if (this.skillSettings.allowSkillCompletion) {
					if (skillProgressItem?.completedDate) {
						skillsDone++;
					}
				} else {
					if (skillProgressItem?.attempts.some((attempt) => attempt.sessionID === this.selectedSession.sessionID)) {
						skillsDone++;
					}
				}

				return {
					...skill,
					score: skillProgressItem?.score,
					attempts: skillProgressItem?.attempts,
					successes: skillProgressItem?.attempts?.filter((a) => a.success).length || 0,
					failures: skillProgressItem?.attempts?.filter((a) => !a.success).length || 0,
					completedDate: skillProgressItem?.completedDate,
					completedBy: skillProgressItem?.completedBy,
				};
			});

			return skillsWithData;
		},
		skillSetsWithSkillsUserData() {
			let skillSetsWithData = [];
			if (!this.institutionSkillSets) { return []; }
			if (!this.selectedSession) { return []; }

			const skillSets = [...this.institutionSkillSets];
			skillSets?.sort((a, b) => a.name.localeCompare(b.name));

			// Map user data to skills on skillsets.
			for (const skillset of skillSets) {
				const mappedSkills = this.mapSkillsToUserData(skillset.skills);

				skillSetsWithData.push({
					...skillset,
					skills: mappedSkills.skills.sort((a, b) => a.name.localeCompare(b.name)),
					totalSkills: mappedSkills.skills.length,
					skillsCompleted: mappedSkills.skillsDone,
					percentCompleted: mappedSkills.skillsDone / mappedSkills.skills.length,
				});
			};

			if (this.allSkillsSkillSet) {
				skillSetsWithData.unshift(this.allSkillsSkillSet);
			}

			return skillSetsWithData;
		},
		allSkillsSkillSet() {
			if (!this.institutionSkills) { return null; }

			let allMappedSkills = this.mapSkillsToUserData(this.institutionSkills);

			// Order our skills.
			allMappedSkills.skills.sort((a, b) => a.name.localeCompare(b.name))

			return {
				id: '',
				name: 'All Skills',
				skills: allMappedSkills.skills,
				totalSkills: allMappedSkills.skills.length,
				skillsCompleted: allMappedSkills.skillsDone,
				percentCompleted: allMappedSkills.skillsDone / this.institutionSkills.length,
			};
		},
		filteredSkillSets() {
			return this.skillSetsWithSkillsUserData.filter(skillSet => {
				return this.skillSetNameSearchMatch(skillSet.name, this.searchText)
					|| skillSet.skills.some(skill => this.skillSetNameSearchMatch(skill.name, this.searchText));
			});
		},
		selectedSkillsetSkills() {
			if (this.hasSkillSets && !this.selectedSkillset?.skills) { return []; }
			let selectedSkills = [];

			// If we don't have skillSets or we don't want to show by skillSet return only the skills with user attempts.
			if (!this.hasSkillSets || !this.skillSettings.showSkillsBySkillSet) {
				selectedSkills = this.skillsWithSkillUserData ?? [];
			} else {
				selectedSkills = this.selectedSkillset.skills;
			}

			// this.skillSearchText
			if (this.skillSearchText) {
				selectedSkills = selectedSkills.filter(skill => {
					return this.skillSetNameSearchMatch( skill.name, this.skillSearchText);
				});
			}
			return selectedSkills;
		},
		selectedSkillset() {
			this.attemptCRUDModal;
			const skillSetID = (this.selectedSkillSetID) ? this.selectedSkillSetID : this.currentDriverProgressSkillSetID;
			let selectedSkills = this.skillSetsWithSkillsUserData?.find(
				(skillset) => skillset.id === skillSetID
			);

			return selectedSkills;
		},
		selectedInstance() {
			if (!this.timeSlot || !this.timeSlot.instances || this.timeSlot.instances.length === 0) { return null; }
			const timeSlotInstance = this.timeSlot.instances.find((inst) => inst.id === this.store.getters.activeSession.instanceID);
			return timeSlotInstance;
		},
		selectedSkill() {
			if (!this.selectedSkillID) { return {}; }
			return this.selectedSkillsetSkills.find((skill) => skill.id === this.selectedSkillID);
		},
		selectedSession() {
			if (!this.selectedSessionGroup || !this.selectedSessionGroup?.sessions || this.selectedSessionGroup.sessions.length === 0) { return null; }

			const selectedSession = this.selectedSessionGroup?.sessions.find(s => s.sessionID === this.store.getters.activeSession.sessionID);
			return selectedSession;
		},
		selectedSessionGroup() {
			if (!this.selectedInstance || !this.selectedInstance.timeGroupedSessions || this.selectedInstance.timeGroupedSessions.length === 0) {
				return null;
			}

			const sessionGroup =  this.selectedInstance.timeGroupedSessions.find((seshGroup) => {
				return seshGroup?.sessions?.find((sesh) => sesh.sessionID === this.store.getters.activeSession.sessionID);
			});

			return sessionGroup;
		},
	},
	methods: {
		attemptUpdate() {
			// this.timeSlotsQuery.fetchMore({
			// 	institutionSlug: this.store.getters.user.institutions[0],
			// 	instructorID: this.store.getters.activeInstructor?.userID,
			// 	day: this.store.getters.activeSession.instanceDate
			// });
		},
		keyCreation(skillItem) {
			let items = [
				skillItem.id,
				(this.selectedSkillset && this.selectedSkillset.id) ? this.selectedSkillset.id : '--',
				this.counter
			];

			return items.join('--');
		},
		mapSkillsToUserData(skills) {

			if (!skills) { return {
				mappedSkills: [],
				skillsDone: 0
			}}

			let skillsDone = 0;
			const mappedSkills = skills?.map((skill) => {
					const skillProgressItem = this.selectedSession?.studentInstructorAppData?.studentSkillProgress?.find(
						(sp) => sp.id === skill.id
					);

					if (this.skillSettings.allowSkillCompletion) {
						if (skillProgressItem?.completedDate) {
							skillsDone++;
						}
					} else {
						// TODO: Updating settings to allow choice of how completed skills are tracked with options of Date, Session, or Any Attempt.
						// if (skillProgressItem?.attempts.some((attempt) => {

						// 	return attempt.sessionID === this.selectedSession.sessionID;
						// })) {
						// 	skillsDone++;
						// }
						if (skillProgressItem?.attempts.length > 0) {
							skillsDone++;
						}
					}

					const mappedSkill = {
						...skill,
						score: skillProgressItem?.score,
						attempts: skillProgressItem?.attempts,
						successes: skillProgressItem?.attempts?.filter((a) => a.success).length || 0,
						failures: skillProgressItem?.attempts?.filter((a) => !a.success).length || 0,
						completedDate: skillProgressItem?.completedDate,
						completedBy: skillProgressItem?.completedBy,
					}

					return mappedSkill;
				});

			return {
				skills: mappedSkills,
				skillsDone
			}
		},
		showSkillDetailsModal(skillID) {
			this.selectedSkillID = skillID;
			this.skillDetailsModal.show = true;
		},
		showSkillSummaryModal(skillID) {
			this.selectedSkillID = skillID;
			this.skillSummaryModal.show = true;
		},
		skillSetNameSearchMatch(value, searchText) {
			return value.replaceAll(' ', '').toLowerCase().includes(searchText.replaceAll(' ', '').toLowerCase());
		},
		switchSkillAttemptCRUDModal(attemptID) {
			this.skillSummaryModal.show = false;
			this.attemptCRUDModal.selectedAttempt = attemptID;
			this.attemptCRUDModal.show = true;
		},
		toggleSearch() {
			if (this.showSkillSearch || this.skillSearchText) {
				this.skillSearchText = '';
				this.showSkillSearch = true;
			}
			this.showSkillSearch = !this.showSkillSearch;
		},
		openAttemptCRUDModal(skillItem) {
			this.attemptCRUDModalItem = skillItem;
			this.attemptCRUDModal.selectedAttempt = '';
			this.attemptCRUDModal.show = true;
		},
		completeSession() {
			this.selectedSkillSetID = null;
			this.closeAccordion();
			this.store.commit('setSessionState', 'completeSession');
			this.$router.push(`/instructor/session`);
		},
		format,
		formatTime,
		closeAccordion() {
			const accordionGroup = this.$refs.accordionGroup?.$el;
			if (!accordionGroup) {
				return;
			}
			accordionGroup.value = undefined;
		},
		backwardsNav() {
			this.closeAccordion();
			this.backwardsNavAlertMessage =
				"Going back to the calendar will stop your current session and any progress of the current session and will not be saved.";
			this.setOpen(true, "backwards");
		},
		selectSkillset(skillSet) {
			this.selectedSkillSetID = skillSet.id;
			this.$refs.skillsetSelectModal.$el.dismiss(null, 'cancel');
		},
	},
	data() {
		return {
			attemptCRUDModalItem: null,
			attemptCRUDModal: {
				selectedAttempt: '',
				show: false
			},
			counter: 0,
			selectedSkillID: null,
			selectedSkillSetID: null,
			searchText: '',
			skillSearchText: '',
			showSkillSearch: false,
			showSkillsetSelectModal: true,
			backwardsNavAlertMessage: "",
			backwardsNavAlertButtons: [
				{
					text: "NO",
					role: "no",
					cssClass: "alert-button-no",
				},
				{
					text: "YES",
					role: "yes",
					cssClass: "alert-button-confirm",
					handler: async () => {
						if (this.selectedSession?.sessionID) {
							await this.store.dispatch('revertCacheState', {sessionID: this.selectedSession.sessionID});
							await this.store.dispatch('clearInitialCacheState', {sessionID: this.selectedSession.sessionID});
						}
						this.selectedSkillSetID = null;
						await this.store.dispatch('clearActiveSession');
						this.router.push("/instructor/home");
					},
				},
			],
			skillDetailsModal: {
				show: false
			},
			skillSummaryModal: {
				show: false
			}
		};
	},
	watch: {
		'selectedSkillSetID'() {
			this.closeAccordion();
		}
	}
});
</script>

<style scoped lang="scss">
.skill-set-toolbar {
	align-items: stretch;
	box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.25);
	display: flex;
	position: relative;
	z-index: 1;

	&__select-skillset,
	&__search {
		--border-radius: 0 !important;
		--box-shadow: none;
		margin: 0;
		--padding-bottom: 2px;
		--padding-top: 2px;
	}

		&__select-skillset {
			--background: var(--ion-color-light);
			--color: var(--ion-color-dark);
			height: auto;
			flex-grow: 1;
			text-align: left;

			&.skill-set-toolbar__select-skillset--minimize {
				display: none;
			}
		}

		&__button-content {
			padding-right: 15px;
			text-transform: initial;
			width: 100%;

			ion-progress-bar {
				-webkit-border-radius: 150px;
				-moz-border-radius: 150px;
				border-radius: 150px;
				height: 9px;
				margin-top: 10px;

				&.complete {
					--background: var(--ion-color-primary-lighten-2);
					--progress-background: var(--ion-color-success);
				}

				&.in-progress {
					--background: var(--ion-color-primary-lighten-2);
					--progress-background: var(--ion-color-warning);
				}
			}
		}

		&__search-bar {
			--background: var(--ion-color-primary-tint);
			color: var(--ion-color-light);
			display: none;
			--padding-start: 20px;

			&.skill-set-toolbar__search-bar--show {
				display: inherit;
			}
		}

		&__search {
			--background: var(--ion-color-primary-tint);
			--border-radius: 0 !important;
			--box-shadow: none;
			height: 60px;
			width: 60px;
		}
}

.session {
	display: flex;
	flex-direction: column;
	justify-content: space-between;

	&__content {
		display: flex;
		flex-direction: column;
		gap: 10px;
	}

	&__tab-bar {
		display: flex;
		height: 60px;
		justify-content: space-between;
		background: var(--ion-color-primary);
	}

	&__details {
		display: flex;
		padding: 10px;
		flex-direction: column;
		justify-content: center;
		align-items: flex-start;
		gap: 5px;
		flex: 1 0 0;
		align-self: stretch;
		color: #ffffff;
		font-weight: 700;
	}

	&__detail-item {
		padding: 0;
		display: flex;
		flex-direction: row;
		& .material-design-icon {
			width: 20px;
			height: 20px;
		}

		span {
			padding-left: 5px;
		}
	}

	&__tab-bar-tab {
		display: flex;
		width: 60px;
		padding: 10px 0px;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		gap: 3px;
		align-self: stretch;
		background-color: var(--ion-color-primary-tint, #405362);

		& .material-design-icon {
			width: 35px;
			height: 35px;
		}
	}

	&__category {
		align-items: center;
		font-weight: 700;
		padding: 15px;
		display: flex;
		flex-direction: row;
		gap: 15px;
		justify-content: space-between;
		background-color: #ffffff;

		& .material-design-icon {
			width: 30px;
			height: 30px;
		}
	}

		&__category-details {
			display: flex;
			flex-direction: column;
			gap: 5px;
			width: 100%;
		}

	&__progress {
		background-color: #ffffff;

		ion-progress-bar {
			-webkit-border-radius: 150px;
			-moz-border-radius: 150px;
			border-radius: 150px;
			height: 9px;

			&.complete {
				--background: var(--ion-color-primary-lighten-2);
				--progress-background: var(--ion-color-success);
			}

			&.in-progress {
				--background: var(--ion-color-primary-lighten-2);
				--progress-background: var(--ion-color-warning);
			}
		}
	}

	&__content {
		background: var(--ion-color-grey);
		padding: 10px;

		ion-accordion-group {
			margin: 0;
			width: 100%;

			ion-accordion,
			ion-accordion:first-of-type,
			ion-accordion:last-of-type {
				border-radius: 6px;
				box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.25);
				margin-bottom: 10px;
				margin-top: 10px;

				&:first-child {
					margin-top: 0;
				}

				&:last-child {
					margin-bottom: 0;
				}
			}
		}
	}
}

.skillset-select-modal {
	padding-top: 170px;

	ion-content {
		--background: var(--ion-color-secondary);
	}

	&__search {
		--background: var(--ion-color-light);
		padding: 0;
	}

	.skillset-select {
		display: flex;
		flex-direction: column;
		gap: 15px;
		height: calc(100% - 43px);
		overflow-y: scroll;
		padding: 15px;

		ion-item {
			box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.25);
		}
	}
}

.tab-green {
	--background: #00b494;
	--border-radius: 0 !important;
	--box-shadow: none;
	height: 100%;
	margin: 0;
	padding: 0;
}
</style>

<style>
.progress,
.progress-indeterminate {
	background: red !important;
}
</style>
